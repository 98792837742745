/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PaymentIntent = {
  __typename: "PaymentIntent",
  paymentIntentID: string,
  clientSecret: string,
  metadata?: PaymentIntentMetaData | null,
  charge?: Charge | null,
};

export type PaymentIntentMetaData = {
  __typename: "PaymentIntentMetaData",
  customerID: string,
  restaurantID: string,
  orderID?: string | null,
  details?: string | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  customerFee?: number | null,
};

export type Charge = {
  __typename: "Charge",
  id?: string | null,
  object?: string | null,
  amount?: number | null,
  amount_captured?: number | null,
  amount_refunded?: number | null,
  balance_transaction?: string | null,
  billing_details?: BillingDetails | null,
  captured?: boolean | null,
  created?: number | null,
  currency?: string | null,
  disputed?: boolean | null,
  livemode?: boolean | null,
  paid?: boolean | null,
  payment_intent?: string | null,
  payment_method?: string | null,
  receipt_url?: string | null,
  refunded?: boolean | null,
  refunds?: Refunds | null,
  status?: string | null,
};

export type BillingDetails = {
  __typename: "BillingDetails",
  address?: Address | null,
  email?: string | null,
  name?: string | null,
  phone?: string | null,
};

export type Address = {
  __typename: "Address",
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  state?: string | null,
};

export type Refunds = {
  __typename: "Refunds",
  object?: string | null,
  has_more?: boolean | null,
  url?: string | null,
};

export type RestaurantInvoices = {
  __typename: "RestaurantInvoices",
  customerID?: string | null,
  hasRefund?: boolean | null,
  invoiceCreatedAt?: string | null,
  invoiceID?: string | null,
  invoiceTotalNet?: number | null,
  invoiceTotalWithRefund?: number | null,
  orderID?: string | null,
  paymentIntentID?: string | null,
  refundAmount?: number | null,
  restaurantID?: string | null,
  voucherChargedTo?: string | null,
  voucherCode?: string | null,
};

export type CustomerInvoice = {
  __typename: "CustomerInvoice",
  invoiceID?: string | null,
  customerID?: string | null,
  orderID?: string | null,
  paymentIntentID?: string | null,
  pdfFileURL?: string | null,
  restaurantID?: string | null,
  totalPrice?: number | null,
  totalPriceWithDiscount?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  customerFee?: number | null,
  voucherAmountOff?: number | null,
  voucherCode?: string | null,
  voucherPercentOff?: number | null,
  voucherType?: string | null,
};

export type PaymentStatus = {
  __typename: "PaymentStatus",
  isPaid?: boolean | null,
  orderID?: string | null,
  paymentIntentID?: string | null,
};

export type CreateInvoiceInput = {
  id?: string | null,
  amount?: number | null,
  customerFee?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  customerID: string,
  customerEmail?: string | null,
  restaurantID: string,
  paymentIntent?: string | null,
  paymentIntentID?: string | null,
  details?: Array< InvoiceDetailInput | null > | null,
  pdfInvoice?: string | null,
  _version?: number | null,
};

export type InvoiceDetailInput = {
  name?: string | null,
  description?: string | null,
  price?: number | null,
  options?: Array< InvoiceOptionsInput | null > | null,
};

export type InvoiceOptionsInput = {
  title?: string | null,
  items?: Array< InvoiceOptionItemInput | null > | null,
};

export type InvoiceOptionItemInput = {
  title?: string | null,
  price?: number | null,
};

export type ModelInvoiceConditionInput = {
  amount?: ModelIntInput | null,
  customerFee?: ModelIntInput | null,
  deliveryFee?: ModelIntInput | null,
  restaurantFee?: ModelIntInput | null,
  customerID?: ModelIDInput | null,
  customerEmail?: ModelStringInput | null,
  restaurantID?: ModelIDInput | null,
  paymentIntent?: ModelStringInput | null,
  paymentIntentID?: ModelStringInput | null,
  pdfInvoice?: ModelStringInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  amount?: number | null,
  customerFee?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  customerID: string,
  customerEmail?: string | null,
  restaurantID: string,
  paymentIntent?: string | null,
  paymentIntentID?: string | null,
  details?:  Array<InvoiceDetail | null > | null,
  pdfInvoice?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type InvoiceDetail = {
  __typename: "InvoiceDetail",
  name?: string | null,
  description?: string | null,
  price?: number | null,
  options?:  Array<InvoiceOptions | null > | null,
};

export type InvoiceOptions = {
  __typename: "InvoiceOptions",
  title?: string | null,
  items?:  Array<InvoiceOptionItem | null > | null,
};

export type InvoiceOptionItem = {
  __typename: "InvoiceOptionItem",
  title?: string | null,
  price?: number | null,
};

export type UpdateInvoiceInput = {
  id: string,
  amount?: number | null,
  customerFee?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  customerID?: string | null,
  customerEmail?: string | null,
  restaurantID?: string | null,
  paymentIntent?: string | null,
  paymentIntentID?: string | null,
  details?: Array< InvoiceDetailInput | null > | null,
  pdfInvoice?: string | null,
  _version?: number | null,
};

export type DeleteInvoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateDishCategoryInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  imageKey?: string | null,
  restaurantID?: string | null,
  order?: number | null,
  _version?: number | null,
};

export type ModelDishCategoryConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  restaurantID?: ModelIDInput | null,
  order?: ModelFloatInput | null,
  and?: Array< ModelDishCategoryConditionInput | null > | null,
  or?: Array< ModelDishCategoryConditionInput | null > | null,
  not?: ModelDishCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DishCategory = {
  __typename: "DishCategory",
  id: string,
  name: string,
  description?: string | null,
  imageKey?: string | null,
  restaurantID?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDishCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  imageKey?: string | null,
  restaurantID?: string | null,
  order?: number | null,
  _version?: number | null,
};

export type DeleteDishCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateDishOptionItemInput = {
  id?: string | null,
  title: string,
  description?: string | null,
  price: number,
  dishOptionID: string,
  _version?: number | null,
};

export type ModelDishOptionItemConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  dishOptionID?: ModelIDInput | null,
  and?: Array< ModelDishOptionItemConditionInput | null > | null,
  or?: Array< ModelDishOptionItemConditionInput | null > | null,
  not?: ModelDishOptionItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DishOptionItem = {
  __typename: "DishOptionItem",
  id: string,
  title: string,
  description?: string | null,
  price: number,
  dishOptionID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDishOptionItemInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  price?: number | null,
  dishOptionID?: string | null,
  _version?: number | null,
};

export type DeleteDishOptionItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateDishOptionInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  multipleSelection?: boolean | null,
  obligatory?: boolean | null,
  dishIDs?: Array< string | null > | null,
  restaurantID?: string | null,
  basketDishID?: string | null,
  orderDishID?: string | null,
  _version?: number | null,
};

export type ModelDishOptionConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  multipleSelection?: ModelBooleanInput | null,
  obligatory?: ModelBooleanInput | null,
  dishIDs?: ModelIDInput | null,
  restaurantID?: ModelIDInput | null,
  basketDishID?: ModelIDInput | null,
  orderDishID?: ModelIDInput | null,
  and?: Array< ModelDishOptionConditionInput | null > | null,
  or?: Array< ModelDishOptionConditionInput | null > | null,
  not?: ModelDishOptionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DishOption = {
  __typename: "DishOption",
  id: string,
  title?: string | null,
  description?: string | null,
  multipleSelection?: boolean | null,
  obligatory?: boolean | null,
  items?: ModelDishOptionItemConnection | null,
  dishIDs?: Array< string | null > | null,
  restaurantID?: string | null,
  basketDishID?: string | null,
  orderDishID?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelDishOptionItemConnection = {
  __typename: "ModelDishOptionItemConnection",
  items:  Array<DishOptionItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateDishOptionInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  multipleSelection?: boolean | null,
  obligatory?: boolean | null,
  dishIDs?: Array< string | null > | null,
  restaurantID?: string | null,
  basketDishID?: string | null,
  orderDishID?: string | null,
  _version?: number | null,
};

export type DeleteDishOptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateDishInput = {
  id?: string | null,
  name: string,
  image?: string | null,
  description: string,
  shortDescription?: string | null,
  price: number,
  restaurantID: string,
  soldOut?: boolean | null,
  allergens?: Array< AllergensInput | null > | null,
  dishType?: DishType | null,
  _version?: number | null,
  dishCategoryId?: string | null,
};

export type AllergensInput = {
  id?: string | null,
  name?: string | null,
};

export enum DishType {
  FOOD = "FOOD",
  DRINK_ALCOHOLIC = "DRINK_ALCOHOLIC",
  DRINK_NON_ALCOHOLIC = "DRINK_NON_ALCOHOLIC",
}


export type ModelDishConditionInput = {
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  description?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  restaurantID?: ModelIDInput | null,
  soldOut?: ModelBooleanInput | null,
  dishType?: ModelDishTypeInput | null,
  and?: Array< ModelDishConditionInput | null > | null,
  or?: Array< ModelDishConditionInput | null > | null,
  not?: ModelDishConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  dishCategoryId?: ModelIDInput | null,
};

export type ModelDishTypeInput = {
  eq?: DishType | null,
  ne?: DishType | null,
};

export type Dish = {
  __typename: "Dish",
  id: string,
  name: string,
  image?: string | null,
  description: string,
  shortDescription?: string | null,
  price: number,
  restaurantID: string,
  soldOut?: boolean | null,
  category?: DishCategory | null,
  allergens?:  Array<Allergens | null > | null,
  dishType?: DishType | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  dishCategoryId?: string | null,
};

export type Allergens = {
  __typename: "Allergens",
  id?: string | null,
  name?: string | null,
};

export type UpdateDishInput = {
  id: string,
  name?: string | null,
  image?: string | null,
  description?: string | null,
  shortDescription?: string | null,
  price?: number | null,
  restaurantID?: string | null,
  soldOut?: boolean | null,
  allergens?: Array< AllergensInput | null > | null,
  dishType?: DishType | null,
  _version?: number | null,
  dishCategoryId?: string | null,
};

export type DeleteDishInput = {
  id: string,
  _version?: number | null,
};

export type CreateBusinessHourRestaurantInput = {
  id?: string | null,
  restaurantID: string,
  periods?: Array< BusinessHourPeriodInput | null > | null,
  day?: number | null,
  _version?: number | null,
};

export type BusinessHourPeriodInput = {
  label?: string | null,
  start?: string | null,
  end?: string | null,
};

export type ModelBusinessHourRestaurantConditionInput = {
  restaurantID?: ModelIDInput | null,
  day?: ModelIntInput | null,
  and?: Array< ModelBusinessHourRestaurantConditionInput | null > | null,
  or?: Array< ModelBusinessHourRestaurantConditionInput | null > | null,
  not?: ModelBusinessHourRestaurantConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type BusinessHourRestaurant = {
  __typename: "BusinessHourRestaurant",
  id: string,
  restaurantID: string,
  periods?:  Array<BusinessHourPeriod | null > | null,
  day?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type BusinessHourPeriod = {
  __typename: "BusinessHourPeriod",
  label?: string | null,
  start?: string | null,
  end?: string | null,
};

export type UpdateBusinessHourRestaurantInput = {
  id: string,
  restaurantID?: string | null,
  periods?: Array< BusinessHourPeriodInput | null > | null,
  day?: number | null,
  _version?: number | null,
};

export type DeleteBusinessHourRestaurantInput = {
  id: string,
  _version?: number | null,
};

export type CreateBusinessHourTastyInput = {
  id?: string | null,
  day?: number | null,
  appConfigID: string,
  periods?: Array< BusinessHourPeriodInput | null > | null,
  _version?: number | null,
};

export type ModelBusinessHourTastyConditionInput = {
  day?: ModelIntInput | null,
  appConfigID?: ModelIDInput | null,
  and?: Array< ModelBusinessHourTastyConditionInput | null > | null,
  or?: Array< ModelBusinessHourTastyConditionInput | null > | null,
  not?: ModelBusinessHourTastyConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type BusinessHourTasty = {
  __typename: "BusinessHourTasty",
  id: string,
  day?: number | null,
  appConfigID: string,
  periods?:  Array<BusinessHourPeriod | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBusinessHourTastyInput = {
  id: string,
  day?: number | null,
  appConfigID?: string | null,
  periods?: Array< BusinessHourPeriodInput | null > | null,
  _version?: number | null,
};

export type DeleteBusinessHourTastyInput = {
  id: string,
  _version?: number | null,
};

export type CreateAppConfigInput = {
  id?: string | null,
  customerFee?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  minimumOrderValue?: number | null,
  openingHours?: string | null,
  maintenance?: MaintenanceInput | null,
  taxRate?: number | null,
  customData?: string | null,
  banner?: BannerInput | null,
  orderStop?: OrderStopInput | null,
  voucherEnabled?: boolean | null,
  _version?: number | null,
};

export type MaintenanceInput = {
  active?: boolean | null,
  title?: string | null,
  description?: string | null,
  type?: string | null,
};

export type BannerInput = {
  visible?: boolean | null,
  imageURL?: string | null,
  linkURL?: string | null,
};

export type OrderStopInput = {
  active?: boolean | null,
  title?: string | null,
  description?: string | null,
};

export type ModelAppConfigConditionInput = {
  customerFee?: ModelFloatInput | null,
  deliveryFee?: ModelFloatInput | null,
  restaurantFee?: ModelFloatInput | null,
  minimumOrderValue?: ModelFloatInput | null,
  openingHours?: ModelStringInput | null,
  taxRate?: ModelFloatInput | null,
  customData?: ModelStringInput | null,
  voucherEnabled?: ModelBooleanInput | null,
  and?: Array< ModelAppConfigConditionInput | null > | null,
  or?: Array< ModelAppConfigConditionInput | null > | null,
  not?: ModelAppConfigConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AppConfig = {
  __typename: "AppConfig",
  id: string,
  customerFee?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  minimumOrderValue?: number | null,
  openingHours?: string | null,
  businessHours?: ModelBusinessHourTastyConnection | null,
  maintenance?: Maintenance | null,
  taxRate?: number | null,
  customData?: string | null,
  banner?: Banner | null,
  orderStop?: OrderStop | null,
  voucherEnabled?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelBusinessHourTastyConnection = {
  __typename: "ModelBusinessHourTastyConnection",
  items:  Array<BusinessHourTasty | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Maintenance = {
  __typename: "Maintenance",
  active?: boolean | null,
  title?: string | null,
  description?: string | null,
  type?: string | null,
};

export type Banner = {
  __typename: "Banner",
  visible?: boolean | null,
  imageURL?: string | null,
  linkURL?: string | null,
};

export type OrderStop = {
  __typename: "OrderStop",
  active?: boolean | null,
  title?: string | null,
  description?: string | null,
};

export type UpdateAppConfigInput = {
  id: string,
  customerFee?: number | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  minimumOrderValue?: number | null,
  openingHours?: string | null,
  maintenance?: MaintenanceInput | null,
  taxRate?: number | null,
  customData?: string | null,
  banner?: BannerInput | null,
  orderStop?: OrderStopInput | null,
  voucherEnabled?: boolean | null,
  _version?: number | null,
};

export type DeleteAppConfigInput = {
  id: string,
  _version?: number | null,
};

export type CreateDriverInput = {
  id?: string | null,
  name: string,
  sub: string,
  email?: string | null,
  password?: string | null,
  lat?: number | null,
  lng?: number | null,
  transportationMode: TransportationModes,
  _version?: number | null,
};

export enum TransportationModes {
  DRIVING = "DRIVING",
  BICYCLING = "BICYCLING",
}


export type ModelDriverConditionInput = {
  name?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  email?: ModelStringInput | null,
  password?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  transportationMode?: ModelTransportationModesInput | null,
  and?: Array< ModelDriverConditionInput | null > | null,
  or?: Array< ModelDriverConditionInput | null > | null,
  not?: ModelDriverConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTransportationModesInput = {
  eq?: TransportationModes | null,
  ne?: TransportationModes | null,
};

export type Driver = {
  __typename: "Driver",
  id: string,
  name: string,
  sub: string,
  email?: string | null,
  password?: string | null,
  lat?: number | null,
  lng?: number | null,
  transportationMode: TransportationModes,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDriverInput = {
  id: string,
  name?: string | null,
  sub?: string | null,
  email?: string | null,
  password?: string | null,
  lat?: number | null,
  lng?: number | null,
  transportationMode?: TransportationModes | null,
  _version?: number | null,
};

export type DeleteDriverInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  sub: string,
  name: string,
  address: string,
  addressData?: AddressDataInput | null,
  email?: string | null,
  phone?: string | null,
  lat: number,
  lng: number,
  deliveryAddressID?: string | null,
  newsletter?: boolean | null,
  company?: string | null,
  birthday?: string | null,
  stripeCustomerID?: string | null,
  _version?: number | null,
};

export type AddressDataInput = {
  street?: string | null,
  city?: string | null,
  country?: string | null,
  district?: string | null,
  formattedAddress?: string | null,
  lat?: number | null,
  lng?: number | null,
};

export type ModelUserConditionInput = {
  sub?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  deliveryAddressID?: ModelStringInput | null,
  newsletter?: ModelBooleanInput | null,
  company?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  stripeCustomerID?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  sub: string,
  name: string,
  address: string,
  addressData?: AddressData | null,
  email?: string | null,
  phone?: string | null,
  lat: number,
  lng: number,
  Orders?: ModelOrderConnection | null,
  Baskets?: ModelBasketConnection | null,
  deliveryAddressID?: string | null,
  DeliveryAddresses?: ModelDeliveryAddressConnection | null,
  invoices?: ModelInvoiceConnection | null,
  newsletter?: boolean | null,
  company?: string | null,
  birthday?: string | null,
  stripeCustomerID?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AddressData = {
  __typename: "AddressData",
  street?: string | null,
  city?: string | null,
  country?: string | null,
  district?: string | null,
  formattedAddress?: string | null,
  lat?: number | null,
  lng?: number | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  userID: string,
  Restaurant?: Restaurant | null,
  total: number,
  totalPaid?: number | null,
  voucherCode?: string | null,
  voucherAmountOff?: number | null,
  voucherType?: string | null,
  voucherPercentOff?: number | null,
  status: OrderStatus,
  OrderDishes?: ModelOrderDishConnection | null,
  Driver?: Driver | null,
  read?: boolean | null,
  printed?: boolean | null,
  orderNumber?: string | null,
  note?: string | null,
  ready?: boolean | null,
  createdAt?: string | null,
  orderRestaurantId?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  orderDriverId?: string | null,
};

export type Restaurant = {
  __typename: "Restaurant",
  id: string,
  adminSub?: string | null,
  listRanking?: number | null,
  name: string,
  image?: ImageData | null,
  minDeliveryTime: number,
  maxDeliveryTime: number,
  rating?: number | null,
  address: string,
  lat: number,
  lng: number,
  Dishes?: ModelDishConnection | null,
  Baskets?: ModelBasketConnection | null,
  categories?: ModelRestaurantCategoryConnection | null,
  imageStorageKey?: string | null,
  status?: RestaurantStatus | null,
  invoices?: ModelInvoiceConnection | null,
  phone?: string | null,
  email?: string | null,
  emailPrinter?: string | null,
  autoPickUp?: string | null,
  customData?: string | null,
  billingDetails?: BillingDetails | null,
  printerGCMToken?: string | null,
  businessHours?: ModelBusinessHourRestaurantConnection | null,
  loggingEnabled?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ImageData = {
  __typename: "ImageData",
  logoKey?: string | null,
  teaserKey?: string | null,
};

export type ModelDishConnection = {
  __typename: "ModelDishConnection",
  items:  Array<Dish | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBasketConnection = {
  __typename: "ModelBasketConnection",
  items:  Array<Basket | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Basket = {
  __typename: "Basket",
  id: string,
  BasketDishes?: ModelBasketDishConnection | null,
  userID: string,
  restaurantID: string,
  note?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelBasketDishConnection = {
  __typename: "ModelBasketDishConnection",
  items:  Array<BasketDish | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type BasketDish = {
  __typename: "BasketDish",
  id: string,
  quantity: number,
  Dish?: Dish | null,
  basketID: string,
  selectedDishOptions?: ModelDishOptionConnection | null,
  selectedDishOptionsJSON?: string | null,
  note?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  basketDishDishId?: string | null,
};

export type ModelDishOptionConnection = {
  __typename: "ModelDishOptionConnection",
  items:  Array<DishOption | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRestaurantCategoryConnection = {
  __typename: "ModelRestaurantCategoryConnection",
  items:  Array<RestaurantCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type RestaurantCategory = {
  __typename: "RestaurantCategory",
  id: string,
  name?: string | null,
  restaurantID?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum RestaurantStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBusinessHourRestaurantConnection = {
  __typename: "ModelBusinessHourRestaurantConnection",
  items:  Array<BusinessHourRestaurant | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum OrderStatus {
  PENDING = "PENDING",
  NEW = "NEW",
  COOKING = "COOKING",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  PICKED_UP = "PICKED_UP",
  COMPLETED = "COMPLETED",
  ACCEPTED = "ACCEPTED",
  DECLINED_BY_RESTAURANT = "DECLINED_BY_RESTAURANT",
}


export type ModelOrderDishConnection = {
  __typename: "ModelOrderDishConnection",
  items:  Array<OrderDish | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrderDish = {
  __typename: "OrderDish",
  id: string,
  quantity: number,
  Dish?: Dish | null,
  orderID: string,
  selectedDishOptions?: ModelDishOptionConnection | null,
  selectedDishOptionsJSON?: string | null,
  note?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  orderDishDishId?: string | null,
};

export type ModelDeliveryAddressConnection = {
  __typename: "ModelDeliveryAddressConnection",
  items:  Array<DeliveryAddress | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type DeliveryAddress = {
  __typename: "DeliveryAddress",
  id: string,
  name?: string | null,
  addressData?: AddressData | null,
  userID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserInput = {
  id: string,
  sub?: string | null,
  name?: string | null,
  address?: string | null,
  addressData?: AddressDataInput | null,
  email?: string | null,
  phone?: string | null,
  lat?: number | null,
  lng?: number | null,
  deliveryAddressID?: string | null,
  newsletter?: boolean | null,
  company?: string | null,
  birthday?: string | null,
  stripeCustomerID?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateBasketDishInput = {
  id?: string | null,
  quantity: number,
  basketID: string,
  selectedDishOptionsJSON?: string | null,
  note?: string | null,
  _version?: number | null,
  basketDishDishId?: string | null,
};

export type ModelBasketDishConditionInput = {
  quantity?: ModelIntInput | null,
  basketID?: ModelIDInput | null,
  selectedDishOptionsJSON?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelBasketDishConditionInput | null > | null,
  or?: Array< ModelBasketDishConditionInput | null > | null,
  not?: ModelBasketDishConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  basketDishDishId?: ModelIDInput | null,
};

export type UpdateBasketDishInput = {
  id: string,
  quantity?: number | null,
  basketID?: string | null,
  selectedDishOptionsJSON?: string | null,
  note?: string | null,
  _version?: number | null,
  basketDishDishId?: string | null,
};

export type DeleteBasketDishInput = {
  id: string,
  _version?: number | null,
};

export type CreateBasketInput = {
  id?: string | null,
  userID: string,
  restaurantID: string,
  note?: string | null,
  _version?: number | null,
};

export type ModelBasketConditionInput = {
  userID?: ModelIDInput | null,
  restaurantID?: ModelIDInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelBasketConditionInput | null > | null,
  or?: Array< ModelBasketConditionInput | null > | null,
  not?: ModelBasketConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBasketInput = {
  id: string,
  userID?: string | null,
  restaurantID?: string | null,
  note?: string | null,
  _version?: number | null,
};

export type DeleteBasketInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderDishInput = {
  id?: string | null,
  quantity: number,
  orderID: string,
  selectedDishOptionsJSON?: string | null,
  note?: string | null,
  _version?: number | null,
  orderDishDishId?: string | null,
};

export type ModelOrderDishConditionInput = {
  quantity?: ModelIntInput | null,
  orderID?: ModelIDInput | null,
  selectedDishOptionsJSON?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelOrderDishConditionInput | null > | null,
  or?: Array< ModelOrderDishConditionInput | null > | null,
  not?: ModelOrderDishConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  orderDishDishId?: ModelIDInput | null,
};

export type UpdateOrderDishInput = {
  id: string,
  quantity?: number | null,
  orderID?: string | null,
  selectedDishOptionsJSON?: string | null,
  note?: string | null,
  _version?: number | null,
  orderDishDishId?: string | null,
};

export type DeleteOrderDishInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderInput = {
  id?: string | null,
  userID: string,
  total: number,
  totalPaid?: number | null,
  voucherCode?: string | null,
  voucherAmountOff?: number | null,
  voucherType?: string | null,
  voucherPercentOff?: number | null,
  status: OrderStatus,
  read?: boolean | null,
  printed?: boolean | null,
  orderNumber?: string | null,
  note?: string | null,
  ready?: boolean | null,
  createdAt?: string | null,
  orderRestaurantId?: string | null,
  _version?: number | null,
  orderDriverId?: string | null,
};

export type ModelOrderConditionInput = {
  userID?: ModelIDInput | null,
  total?: ModelFloatInput | null,
  totalPaid?: ModelFloatInput | null,
  voucherCode?: ModelStringInput | null,
  voucherAmountOff?: ModelFloatInput | null,
  voucherType?: ModelStringInput | null,
  voucherPercentOff?: ModelFloatInput | null,
  status?: ModelOrderStatusInput | null,
  read?: ModelBooleanInput | null,
  printed?: ModelBooleanInput | null,
  orderNumber?: ModelStringInput | null,
  note?: ModelStringInput | null,
  ready?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  orderRestaurantId?: ModelIDInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  orderDriverId?: ModelIDInput | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type UpdateOrderInput = {
  id: string,
  userID?: string | null,
  total?: number | null,
  totalPaid?: number | null,
  voucherCode?: string | null,
  voucherAmountOff?: number | null,
  voucherType?: string | null,
  voucherPercentOff?: number | null,
  status?: OrderStatus | null,
  read?: boolean | null,
  printed?: boolean | null,
  orderNumber?: string | null,
  note?: string | null,
  ready?: boolean | null,
  createdAt?: string | null,
  orderRestaurantId?: string | null,
  _version?: number | null,
  orderDriverId?: string | null,
};

export type DeleteOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateDeliveryAddressInput = {
  id?: string | null,
  name?: string | null,
  addressData?: AddressDataInput | null,
  userID: string,
  _version?: number | null,
};

export type ModelDeliveryAddressConditionInput = {
  name?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelDeliveryAddressConditionInput | null > | null,
  or?: Array< ModelDeliveryAddressConditionInput | null > | null,
  not?: ModelDeliveryAddressConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateDeliveryAddressInput = {
  id: string,
  name?: string | null,
  addressData?: AddressDataInput | null,
  userID?: string | null,
  _version?: number | null,
};

export type DeleteDeliveryAddressInput = {
  id: string,
  _version?: number | null,
};

export type CreateRestaurantCategoryInput = {
  id?: string | null,
  name?: string | null,
  restaurantID?: string | null,
  _version?: number | null,
};

export type ModelRestaurantCategoryConditionInput = {
  name?: ModelStringInput | null,
  restaurantID?: ModelIDInput | null,
  and?: Array< ModelRestaurantCategoryConditionInput | null > | null,
  or?: Array< ModelRestaurantCategoryConditionInput | null > | null,
  not?: ModelRestaurantCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateRestaurantCategoryInput = {
  id: string,
  name?: string | null,
  restaurantID?: string | null,
  _version?: number | null,
};

export type DeleteRestaurantCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateRestaurantInput = {
  id?: string | null,
  adminSub?: string | null,
  listRanking?: number | null,
  name: string,
  image?: ImageDataInput | null,
  minDeliveryTime: number,
  maxDeliveryTime: number,
  rating?: number | null,
  address: string,
  lat: number,
  lng: number,
  imageStorageKey?: string | null,
  status?: RestaurantStatus | null,
  phone?: string | null,
  email?: string | null,
  emailPrinter?: string | null,
  autoPickUp?: string | null,
  customData?: string | null,
  billingDetails?: BillingDetailsInput | null,
  printerGCMToken?: string | null,
  loggingEnabled?: boolean | null,
  _version?: number | null,
};

export type ImageDataInput = {
  logoKey?: string | null,
  teaserKey?: string | null,
};

export type BillingDetailsInput = {
  address?: AddressInput | null,
  email?: string | null,
  name?: string | null,
  phone?: string | null,
};

export type AddressInput = {
  city?: string | null,
  country?: string | null,
  line1?: string | null,
  line2?: string | null,
  postal_code?: string | null,
  state?: string | null,
};

export type ModelRestaurantConditionInput = {
  adminSub?: ModelStringInput | null,
  listRanking?: ModelFloatInput | null,
  name?: ModelStringInput | null,
  minDeliveryTime?: ModelIntInput | null,
  maxDeliveryTime?: ModelIntInput | null,
  rating?: ModelFloatInput | null,
  address?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  imageStorageKey?: ModelStringInput | null,
  status?: ModelRestaurantStatusInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  emailPrinter?: ModelStringInput | null,
  autoPickUp?: ModelStringInput | null,
  customData?: ModelStringInput | null,
  printerGCMToken?: ModelStringInput | null,
  loggingEnabled?: ModelBooleanInput | null,
  and?: Array< ModelRestaurantConditionInput | null > | null,
  or?: Array< ModelRestaurantConditionInput | null > | null,
  not?: ModelRestaurantConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelRestaurantStatusInput = {
  eq?: RestaurantStatus | null,
  ne?: RestaurantStatus | null,
};

export type UpdateRestaurantInput = {
  id: string,
  adminSub?: string | null,
  listRanking?: number | null,
  name?: string | null,
  image?: ImageDataInput | null,
  minDeliveryTime?: number | null,
  maxDeliveryTime?: number | null,
  rating?: number | null,
  address?: string | null,
  lat?: number | null,
  lng?: number | null,
  imageStorageKey?: string | null,
  status?: RestaurantStatus | null,
  phone?: string | null,
  email?: string | null,
  emailPrinter?: string | null,
  autoPickUp?: string | null,
  customData?: string | null,
  billingDetails?: BillingDetailsInput | null,
  printerGCMToken?: string | null,
  loggingEnabled?: boolean | null,
  _version?: number | null,
};

export type DeleteRestaurantInput = {
  id: string,
  _version?: number | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  customerFee?: ModelIntInput | null,
  deliveryFee?: ModelIntInput | null,
  restaurantFee?: ModelIntInput | null,
  customerID?: ModelIDInput | null,
  customerEmail?: ModelStringInput | null,
  restaurantID?: ModelIDInput | null,
  paymentIntent?: ModelStringInput | null,
  paymentIntentID?: ModelStringInput | null,
  pdfInvoice?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelDishCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  restaurantID?: ModelIDInput | null,
  order?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDishCategoryFilterInput | null > | null,
  or?: Array< ModelDishCategoryFilterInput | null > | null,
  not?: ModelDishCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDishCategoryConnection = {
  __typename: "ModelDishCategoryConnection",
  items:  Array<DishCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDishOptionItemFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  dishOptionID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDishOptionItemFilterInput | null > | null,
  or?: Array< ModelDishOptionItemFilterInput | null > | null,
  not?: ModelDishOptionItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDishOptionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  multipleSelection?: ModelBooleanInput | null,
  obligatory?: ModelBooleanInput | null,
  dishIDs?: ModelIDInput | null,
  restaurantID?: ModelIDInput | null,
  basketDishID?: ModelIDInput | null,
  orderDishID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDishOptionFilterInput | null > | null,
  or?: Array< ModelDishOptionFilterInput | null > | null,
  not?: ModelDishOptionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDishFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  description?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  restaurantID?: ModelIDInput | null,
  soldOut?: ModelBooleanInput | null,
  dishType?: ModelDishTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDishFilterInput | null > | null,
  or?: Array< ModelDishFilterInput | null > | null,
  not?: ModelDishFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  dishCategoryId?: ModelIDInput | null,
};

export type ModelBusinessHourRestaurantFilterInput = {
  id?: ModelIDInput | null,
  restaurantID?: ModelIDInput | null,
  day?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBusinessHourRestaurantFilterInput | null > | null,
  or?: Array< ModelBusinessHourRestaurantFilterInput | null > | null,
  not?: ModelBusinessHourRestaurantFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBusinessHourTastyFilterInput = {
  id?: ModelIDInput | null,
  day?: ModelIntInput | null,
  appConfigID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBusinessHourTastyFilterInput | null > | null,
  or?: Array< ModelBusinessHourTastyFilterInput | null > | null,
  not?: ModelBusinessHourTastyFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAppConfigFilterInput = {
  id?: ModelIDInput | null,
  customerFee?: ModelFloatInput | null,
  deliveryFee?: ModelFloatInput | null,
  restaurantFee?: ModelFloatInput | null,
  minimumOrderValue?: ModelFloatInput | null,
  openingHours?: ModelStringInput | null,
  taxRate?: ModelFloatInput | null,
  customData?: ModelStringInput | null,
  voucherEnabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAppConfigFilterInput | null > | null,
  or?: Array< ModelAppConfigFilterInput | null > | null,
  not?: ModelAppConfigFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAppConfigConnection = {
  __typename: "ModelAppConfigConnection",
  items:  Array<AppConfig | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDriverFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  email?: ModelStringInput | null,
  password?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  transportationMode?: ModelTransportationModesInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDriverFilterInput | null > | null,
  or?: Array< ModelDriverFilterInput | null > | null,
  not?: ModelDriverFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDriverConnection = {
  __typename: "ModelDriverConnection",
  items:  Array<Driver | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  deliveryAddressID?: ModelStringInput | null,
  newsletter?: ModelBooleanInput | null,
  company?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  stripeCustomerID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBasketDishFilterInput = {
  id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  basketID?: ModelIDInput | null,
  selectedDishOptionsJSON?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBasketDishFilterInput | null > | null,
  or?: Array< ModelBasketDishFilterInput | null > | null,
  not?: ModelBasketDishFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  basketDishDishId?: ModelIDInput | null,
};

export type ModelBasketFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  restaurantID?: ModelIDInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBasketFilterInput | null > | null,
  or?: Array< ModelBasketFilterInput | null > | null,
  not?: ModelBasketFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrderDishFilterInput = {
  id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  orderID?: ModelIDInput | null,
  selectedDishOptionsJSON?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderDishFilterInput | null > | null,
  or?: Array< ModelOrderDishFilterInput | null > | null,
  not?: ModelOrderDishFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  orderDishDishId?: ModelIDInput | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  total?: ModelFloatInput | null,
  totalPaid?: ModelFloatInput | null,
  voucherCode?: ModelStringInput | null,
  voucherAmountOff?: ModelFloatInput | null,
  voucherType?: ModelStringInput | null,
  voucherPercentOff?: ModelFloatInput | null,
  status?: ModelOrderStatusInput | null,
  read?: ModelBooleanInput | null,
  printed?: ModelBooleanInput | null,
  orderNumber?: ModelStringInput | null,
  note?: ModelStringInput | null,
  ready?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  orderRestaurantId?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  orderDriverId?: ModelIDInput | null,
};

export type ModelDeliveryAddressFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDeliveryAddressFilterInput | null > | null,
  or?: Array< ModelDeliveryAddressFilterInput | null > | null,
  not?: ModelDeliveryAddressFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRestaurantCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  restaurantID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRestaurantCategoryFilterInput | null > | null,
  or?: Array< ModelRestaurantCategoryFilterInput | null > | null,
  not?: ModelRestaurantCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRestaurantFilterInput = {
  id?: ModelIDInput | null,
  adminSub?: ModelStringInput | null,
  listRanking?: ModelFloatInput | null,
  name?: ModelStringInput | null,
  minDeliveryTime?: ModelIntInput | null,
  maxDeliveryTime?: ModelIntInput | null,
  rating?: ModelFloatInput | null,
  address?: ModelStringInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  imageStorageKey?: ModelStringInput | null,
  status?: ModelRestaurantStatusInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  emailPrinter?: ModelStringInput | null,
  autoPickUp?: ModelStringInput | null,
  customData?: ModelStringInput | null,
  printerGCMToken?: ModelStringInput | null,
  loggingEnabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRestaurantFilterInput | null > | null,
  or?: Array< ModelRestaurantFilterInput | null > | null,
  not?: ModelRestaurantFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRestaurantConnection = {
  __typename: "ModelRestaurantConnection",
  items:  Array<Restaurant | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionInvoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionIntInput | null,
  customerFee?: ModelSubscriptionIntInput | null,
  deliveryFee?: ModelSubscriptionIntInput | null,
  restaurantFee?: ModelSubscriptionIntInput | null,
  customerID?: ModelSubscriptionIDInput | null,
  customerEmail?: ModelSubscriptionStringInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  paymentIntent?: ModelSubscriptionStringInput | null,
  paymentIntentID?: ModelSubscriptionStringInput | null,
  pdfInvoice?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionDishCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  imageKey?: ModelSubscriptionStringInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  order?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDishCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionDishCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDishOptionItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  dishOptionID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDishOptionItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionDishOptionItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDishOptionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  multipleSelection?: ModelSubscriptionBooleanInput | null,
  obligatory?: ModelSubscriptionBooleanInput | null,
  dishIDs?: ModelSubscriptionIDInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  basketDishID?: ModelSubscriptionIDInput | null,
  orderDishID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDishOptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDishOptionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionDishFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  soldOut?: ModelSubscriptionBooleanInput | null,
  dishType?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDishFilterInput | null > | null,
  or?: Array< ModelSubscriptionDishFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  dishCategoryId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBusinessHourRestaurantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  day?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBusinessHourRestaurantFilterInput | null > | null,
  or?: Array< ModelSubscriptionBusinessHourRestaurantFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBusinessHourTastyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  day?: ModelSubscriptionIntInput | null,
  appConfigID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBusinessHourTastyFilterInput | null > | null,
  or?: Array< ModelSubscriptionBusinessHourTastyFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAppConfigFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customerFee?: ModelSubscriptionFloatInput | null,
  deliveryFee?: ModelSubscriptionFloatInput | null,
  restaurantFee?: ModelSubscriptionFloatInput | null,
  minimumOrderValue?: ModelSubscriptionFloatInput | null,
  openingHours?: ModelSubscriptionStringInput | null,
  taxRate?: ModelSubscriptionFloatInput | null,
  customData?: ModelSubscriptionStringInput | null,
  voucherEnabled?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAppConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionAppConfigFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDriverFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  sub?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  password?: ModelSubscriptionStringInput | null,
  lat?: ModelSubscriptionFloatInput | null,
  lng?: ModelSubscriptionFloatInput | null,
  transportationMode?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDriverFilterInput | null > | null,
  or?: Array< ModelSubscriptionDriverFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sub?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  lat?: ModelSubscriptionFloatInput | null,
  lng?: ModelSubscriptionFloatInput | null,
  deliveryAddressID?: ModelSubscriptionStringInput | null,
  newsletter?: ModelSubscriptionBooleanInput | null,
  company?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionStringInput | null,
  stripeCustomerID?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBasketDishFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  basketID?: ModelSubscriptionIDInput | null,
  selectedDishOptionsJSON?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBasketDishFilterInput | null > | null,
  or?: Array< ModelSubscriptionBasketDishFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  basketDishDishId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBasketFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBasketFilterInput | null > | null,
  or?: Array< ModelSubscriptionBasketFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrderDishFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  orderID?: ModelSubscriptionIDInput | null,
  selectedDishOptionsJSON?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderDishFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderDishFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  orderDishDishId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  total?: ModelSubscriptionFloatInput | null,
  totalPaid?: ModelSubscriptionFloatInput | null,
  voucherCode?: ModelSubscriptionStringInput | null,
  voucherAmountOff?: ModelSubscriptionFloatInput | null,
  voucherType?: ModelSubscriptionStringInput | null,
  voucherPercentOff?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  read?: ModelSubscriptionBooleanInput | null,
  printed?: ModelSubscriptionBooleanInput | null,
  orderNumber?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  ready?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  orderRestaurantId?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  orderDriverId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionDeliveryAddressFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeliveryAddressFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeliveryAddressFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRestaurantCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  restaurantID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRestaurantCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionRestaurantCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRestaurantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  adminSub?: ModelSubscriptionStringInput | null,
  listRanking?: ModelSubscriptionFloatInput | null,
  name?: ModelSubscriptionStringInput | null,
  minDeliveryTime?: ModelSubscriptionIntInput | null,
  maxDeliveryTime?: ModelSubscriptionIntInput | null,
  rating?: ModelSubscriptionFloatInput | null,
  address?: ModelSubscriptionStringInput | null,
  lat?: ModelSubscriptionFloatInput | null,
  lng?: ModelSubscriptionFloatInput | null,
  imageStorageKey?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  emailPrinter?: ModelSubscriptionStringInput | null,
  autoPickUp?: ModelSubscriptionStringInput | null,
  customData?: ModelSubscriptionStringInput | null,
  printerGCMToken?: ModelSubscriptionStringInput | null,
  loggingEnabled?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRestaurantFilterInput | null > | null,
  or?: Array< ModelSubscriptionRestaurantFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreatePaymentIntentMutationVariables = {
  amount: number,
  customerID?: string | null,
  restaurantID?: string | null,
  email?: string | null,
  details?: string | null,
  deliveryFee?: number | null,
  restaurantFee?: number | null,
  customerFee?: number | null,
  voucherCode?: string | null,
  voucherAmountOff?: number | null,
  voucherType?: string | null,
  voucherPercentOff?: number | null,
  totalPrice?: number | null,
  totalPriceWithDiscount?: number | null,
};

export type CreatePaymentIntentMutation = {
  createPaymentIntent:  {
    __typename: "PaymentIntent",
    paymentIntentID: string,
    clientSecret: string,
    metadata?:  {
      __typename: "PaymentIntentMetaData",
      customerID: string,
      restaurantID: string,
      orderID?: string | null,
      details?: string | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      customerFee?: number | null,
    } | null,
    charge?:  {
      __typename: "Charge",
      id?: string | null,
      object?: string | null,
      amount?: number | null,
      amount_captured?: number | null,
      amount_refunded?: number | null,
      balance_transaction?: string | null,
      billing_details?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      captured?: boolean | null,
      created?: number | null,
      currency?: string | null,
      disputed?: boolean | null,
      livemode?: boolean | null,
      paid?: boolean | null,
      payment_intent?: string | null,
      payment_method?: string | null,
      receipt_url?: string | null,
      refunded?: boolean | null,
      refunds?:  {
        __typename: "Refunds",
        object?: string | null,
        has_more?: boolean | null,
        url?: string | null,
      } | null,
      status?: string | null,
    } | null,
  },
};

export type UpdatePaymentIntentMutationVariables = {
  paymentIntentID: string,
  orderID: string,
};

export type UpdatePaymentIntentMutation = {
  updatePaymentIntent:  {
    __typename: "PaymentIntent",
    paymentIntentID: string,
    clientSecret: string,
    metadata?:  {
      __typename: "PaymentIntentMetaData",
      customerID: string,
      restaurantID: string,
      orderID?: string | null,
      details?: string | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      customerFee?: number | null,
    } | null,
    charge?:  {
      __typename: "Charge",
      id?: string | null,
      object?: string | null,
      amount?: number | null,
      amount_captured?: number | null,
      amount_refunded?: number | null,
      balance_transaction?: string | null,
      billing_details?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      captured?: boolean | null,
      created?: number | null,
      currency?: string | null,
      disputed?: boolean | null,
      livemode?: boolean | null,
      paid?: boolean | null,
      payment_intent?: string | null,
      payment_method?: string | null,
      receipt_url?: string | null,
      refunded?: boolean | null,
      refunds?:  {
        __typename: "Refunds",
        object?: string | null,
        has_more?: boolean | null,
        url?: string | null,
      } | null,
      status?: string | null,
    } | null,
  },
};

export type GetPaymentIntentMutationVariables = {
  paymentIntentID: string,
};

export type GetPaymentIntentMutation = {
  getPaymentIntent:  {
    __typename: "PaymentIntent",
    paymentIntentID: string,
    clientSecret: string,
    metadata?:  {
      __typename: "PaymentIntentMetaData",
      customerID: string,
      restaurantID: string,
      orderID?: string | null,
      details?: string | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      customerFee?: number | null,
    } | null,
    charge?:  {
      __typename: "Charge",
      id?: string | null,
      object?: string | null,
      amount?: number | null,
      amount_captured?: number | null,
      amount_refunded?: number | null,
      balance_transaction?: string | null,
      billing_details?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      captured?: boolean | null,
      created?: number | null,
      currency?: string | null,
      disputed?: boolean | null,
      livemode?: boolean | null,
      paid?: boolean | null,
      payment_intent?: string | null,
      payment_method?: string | null,
      receipt_url?: string | null,
      refunded?: boolean | null,
      refunds?:  {
        __typename: "Refunds",
        object?: string | null,
        has_more?: boolean | null,
        url?: string | null,
      } | null,
      status?: string | null,
    } | null,
  },
};

export type CalculateRestaurantInvoiceMutationVariables = {
  restaurantID: string,
  startDate: string,
  endDate: string,
};

export type CalculateRestaurantInvoiceMutation = {
  calculateRestaurantInvoice?:  Array< {
    __typename: "RestaurantInvoices",
    customerID?: string | null,
    hasRefund?: boolean | null,
    invoiceCreatedAt?: string | null,
    invoiceID?: string | null,
    invoiceTotalNet?: number | null,
    invoiceTotalWithRefund?: number | null,
    orderID?: string | null,
    paymentIntentID?: string | null,
    refundAmount?: number | null,
    restaurantID?: string | null,
    voucherChargedTo?: string | null,
    voucherCode?: string | null,
  } | null > | null,
};

export type CalculateCustomerInvoiceMutationVariables = {
  invoiceID: string,
};

export type CalculateCustomerInvoiceMutation = {
  calculateCustomerInvoice?:  {
    __typename: "CustomerInvoice",
    invoiceID?: string | null,
    customerID?: string | null,
    orderID?: string | null,
    paymentIntentID?: string | null,
    pdfFileURL?: string | null,
    restaurantID?: string | null,
    totalPrice?: number | null,
    totalPriceWithDiscount?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerFee?: number | null,
    voucherAmountOff?: number | null,
    voucherCode?: string | null,
    voucherPercentOff?: number | null,
    voucherType?: string | null,
  } | null,
};

export type CheckPaymentStatusMutationVariables = {
  orderID: string,
};

export type CheckPaymentStatusMutation = {
  checkPaymentStatus?:  {
    __typename: "PaymentStatus",
    isPaid?: boolean | null,
    orderID?: string | null,
    paymentIntentID?: string | null,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDishCategoryMutationVariables = {
  input: CreateDishCategoryInput,
  condition?: ModelDishCategoryConditionInput | null,
};

export type CreateDishCategoryMutation = {
  createDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDishCategoryMutationVariables = {
  input: UpdateDishCategoryInput,
  condition?: ModelDishCategoryConditionInput | null,
};

export type UpdateDishCategoryMutation = {
  updateDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDishCategoryMutationVariables = {
  input: DeleteDishCategoryInput,
  condition?: ModelDishCategoryConditionInput | null,
};

export type DeleteDishCategoryMutation = {
  deleteDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDishOptionItemMutationVariables = {
  input: CreateDishOptionItemInput,
  condition?: ModelDishOptionItemConditionInput | null,
};

export type CreateDishOptionItemMutation = {
  createDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDishOptionItemMutationVariables = {
  input: UpdateDishOptionItemInput,
  condition?: ModelDishOptionItemConditionInput | null,
};

export type UpdateDishOptionItemMutation = {
  updateDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDishOptionItemMutationVariables = {
  input: DeleteDishOptionItemInput,
  condition?: ModelDishOptionItemConditionInput | null,
};

export type DeleteDishOptionItemMutation = {
  deleteDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDishOptionMutationVariables = {
  input: CreateDishOptionInput,
  condition?: ModelDishOptionConditionInput | null,
};

export type CreateDishOptionMutation = {
  createDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDishOptionMutationVariables = {
  input: UpdateDishOptionInput,
  condition?: ModelDishOptionConditionInput | null,
};

export type UpdateDishOptionMutation = {
  updateDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDishOptionMutationVariables = {
  input: DeleteDishOptionInput,
  condition?: ModelDishOptionConditionInput | null,
};

export type DeleteDishOptionMutation = {
  deleteDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDishMutationVariables = {
  input: CreateDishInput,
  condition?: ModelDishConditionInput | null,
};

export type CreateDishMutation = {
  createDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type UpdateDishMutationVariables = {
  input: UpdateDishInput,
  condition?: ModelDishConditionInput | null,
};

export type UpdateDishMutation = {
  updateDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type DeleteDishMutationVariables = {
  input: DeleteDishInput,
  condition?: ModelDishConditionInput | null,
};

export type DeleteDishMutation = {
  deleteDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type CreateBusinessHourRestaurantMutationVariables = {
  input: CreateBusinessHourRestaurantInput,
  condition?: ModelBusinessHourRestaurantConditionInput | null,
};

export type CreateBusinessHourRestaurantMutation = {
  createBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBusinessHourRestaurantMutationVariables = {
  input: UpdateBusinessHourRestaurantInput,
  condition?: ModelBusinessHourRestaurantConditionInput | null,
};

export type UpdateBusinessHourRestaurantMutation = {
  updateBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBusinessHourRestaurantMutationVariables = {
  input: DeleteBusinessHourRestaurantInput,
  condition?: ModelBusinessHourRestaurantConditionInput | null,
};

export type DeleteBusinessHourRestaurantMutation = {
  deleteBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBusinessHourTastyMutationVariables = {
  input: CreateBusinessHourTastyInput,
  condition?: ModelBusinessHourTastyConditionInput | null,
};

export type CreateBusinessHourTastyMutation = {
  createBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBusinessHourTastyMutationVariables = {
  input: UpdateBusinessHourTastyInput,
  condition?: ModelBusinessHourTastyConditionInput | null,
};

export type UpdateBusinessHourTastyMutation = {
  updateBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBusinessHourTastyMutationVariables = {
  input: DeleteBusinessHourTastyInput,
  condition?: ModelBusinessHourTastyConditionInput | null,
};

export type DeleteBusinessHourTastyMutation = {
  deleteBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAppConfigMutationVariables = {
  input: CreateAppConfigInput,
  condition?: ModelAppConfigConditionInput | null,
};

export type CreateAppConfigMutation = {
  createAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAppConfigMutationVariables = {
  input: UpdateAppConfigInput,
  condition?: ModelAppConfigConditionInput | null,
};

export type UpdateAppConfigMutation = {
  updateAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAppConfigMutationVariables = {
  input: DeleteAppConfigInput,
  condition?: ModelAppConfigConditionInput | null,
};

export type DeleteAppConfigMutation = {
  deleteAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDriverMutationVariables = {
  input: CreateDriverInput,
  condition?: ModelDriverConditionInput | null,
};

export type CreateDriverMutation = {
  createDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDriverMutationVariables = {
  input: UpdateDriverInput,
  condition?: ModelDriverConditionInput | null,
};

export type UpdateDriverMutation = {
  updateDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDriverMutationVariables = {
  input: DeleteDriverInput,
  condition?: ModelDriverConditionInput | null,
};

export type DeleteDriverMutation = {
  deleteDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBasketDishMutationVariables = {
  input: CreateBasketDishInput,
  condition?: ModelBasketDishConditionInput | null,
};

export type CreateBasketDishMutation = {
  createBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type UpdateBasketDishMutationVariables = {
  input: UpdateBasketDishInput,
  condition?: ModelBasketDishConditionInput | null,
};

export type UpdateBasketDishMutation = {
  updateBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type DeleteBasketDishMutationVariables = {
  input: DeleteBasketDishInput,
  condition?: ModelBasketDishConditionInput | null,
};

export type DeleteBasketDishMutation = {
  deleteBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type CreateBasketMutationVariables = {
  input: CreateBasketInput,
  condition?: ModelBasketConditionInput | null,
};

export type CreateBasketMutation = {
  createBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBasketMutationVariables = {
  input: UpdateBasketInput,
  condition?: ModelBasketConditionInput | null,
};

export type UpdateBasketMutation = {
  updateBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBasketMutationVariables = {
  input: DeleteBasketInput,
  condition?: ModelBasketConditionInput | null,
};

export type DeleteBasketMutation = {
  deleteBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrderDishMutationVariables = {
  input: CreateOrderDishInput,
  condition?: ModelOrderDishConditionInput | null,
};

export type CreateOrderDishMutation = {
  createOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type UpdateOrderDishMutationVariables = {
  input: UpdateOrderDishInput,
  condition?: ModelOrderDishConditionInput | null,
};

export type UpdateOrderDishMutation = {
  updateOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type DeleteOrderDishMutationVariables = {
  input: DeleteOrderDishInput,
  condition?: ModelOrderDishConditionInput | null,
};

export type DeleteOrderDishMutation = {
  deleteOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type CreateDeliveryAddressMutationVariables = {
  input: CreateDeliveryAddressInput,
  condition?: ModelDeliveryAddressConditionInput | null,
};

export type CreateDeliveryAddressMutation = {
  createDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDeliveryAddressMutationVariables = {
  input: UpdateDeliveryAddressInput,
  condition?: ModelDeliveryAddressConditionInput | null,
};

export type UpdateDeliveryAddressMutation = {
  updateDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDeliveryAddressMutationVariables = {
  input: DeleteDeliveryAddressInput,
  condition?: ModelDeliveryAddressConditionInput | null,
};

export type DeleteDeliveryAddressMutation = {
  deleteDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRestaurantCategoryMutationVariables = {
  input: CreateRestaurantCategoryInput,
  condition?: ModelRestaurantCategoryConditionInput | null,
};

export type CreateRestaurantCategoryMutation = {
  createRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRestaurantCategoryMutationVariables = {
  input: UpdateRestaurantCategoryInput,
  condition?: ModelRestaurantCategoryConditionInput | null,
};

export type UpdateRestaurantCategoryMutation = {
  updateRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRestaurantCategoryMutationVariables = {
  input: DeleteRestaurantCategoryInput,
  condition?: ModelRestaurantCategoryConditionInput | null,
};

export type DeleteRestaurantCategoryMutation = {
  deleteRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRestaurantMutationVariables = {
  input: CreateRestaurantInput,
  condition?: ModelRestaurantConditionInput | null,
};

export type CreateRestaurantMutation = {
  createRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRestaurantMutationVariables = {
  input: UpdateRestaurantInput,
  condition?: ModelRestaurantConditionInput | null,
};

export type UpdateRestaurantMutation = {
  updateRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRestaurantMutationVariables = {
  input: DeleteRestaurantInput,
  condition?: ModelRestaurantConditionInput | null,
};

export type DeleteRestaurantMutation = {
  deleteRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      amount?: number | null,
      customerFee?: number | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      customerID: string,
      customerEmail?: string | null,
      restaurantID: string,
      paymentIntent?: string | null,
      paymentIntentID?: string | null,
      details?:  Array< {
        __typename: "InvoiceDetail",
        name?: string | null,
        description?: string | null,
        price?: number | null,
        options?:  Array< {
          __typename: "InvoiceOptions",
          title?: string | null,
        } | null > | null,
      } | null > | null,
      pdfInvoice?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoicesQuery = {
  syncInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      amount?: number | null,
      customerFee?: number | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      customerID: string,
      customerEmail?: string | null,
      restaurantID: string,
      paymentIntent?: string | null,
      paymentIntentID?: string | null,
      details?:  Array< {
        __typename: "InvoiceDetail",
        name?: string | null,
        description?: string | null,
        price?: number | null,
        options?:  Array< {
          __typename: "InvoiceOptions",
          title?: string | null,
        } | null > | null,
      } | null > | null,
      pdfInvoice?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListInvoicesByCustomerIDQueryVariables = {
  customerID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesByCustomerIDQuery = {
  listInvoicesByCustomerID?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      amount?: number | null,
      customerFee?: number | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      customerID: string,
      customerEmail?: string | null,
      restaurantID: string,
      paymentIntent?: string | null,
      paymentIntentID?: string | null,
      details?:  Array< {
        __typename: "InvoiceDetail",
        name?: string | null,
        description?: string | null,
        price?: number | null,
        options?:  Array< {
          __typename: "InvoiceOptions",
          title?: string | null,
        } | null > | null,
      } | null > | null,
      pdfInvoice?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDishCategoryQueryVariables = {
  id: string,
};

export type GetDishCategoryQuery = {
  getDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDishCategoriesQueryVariables = {
  filter?: ModelDishCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDishCategoriesQuery = {
  listDishCategories?:  {
    __typename: "ModelDishCategoryConnection",
    items:  Array< {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDishCategoriesQueryVariables = {
  filter?: ModelDishCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDishCategoriesQuery = {
  syncDishCategories?:  {
    __typename: "ModelDishCategoryConnection",
    items:  Array< {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDishOptionItemQueryVariables = {
  id: string,
};

export type GetDishOptionItemQuery = {
  getDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDishOptionItemsQueryVariables = {
  filter?: ModelDishOptionItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDishOptionItemsQuery = {
  listDishOptionItems?:  {
    __typename: "ModelDishOptionItemConnection",
    items:  Array< {
      __typename: "DishOptionItem",
      id: string,
      title: string,
      description?: string | null,
      price: number,
      dishOptionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDishOptionItemsQueryVariables = {
  filter?: ModelDishOptionItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDishOptionItemsQuery = {
  syncDishOptionItems?:  {
    __typename: "ModelDishOptionItemConnection",
    items:  Array< {
      __typename: "DishOptionItem",
      id: string,
      title: string,
      description?: string | null,
      price: number,
      dishOptionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDishOptionQueryVariables = {
  id: string,
};

export type GetDishOptionQuery = {
  getDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDishOptionsQueryVariables = {
  filter?: ModelDishOptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDishOptionsQuery = {
  listDishOptions?:  {
    __typename: "ModelDishOptionConnection",
    items:  Array< {
      __typename: "DishOption",
      id: string,
      title?: string | null,
      description?: string | null,
      multipleSelection?: boolean | null,
      obligatory?: boolean | null,
      items?:  {
        __typename: "ModelDishOptionItemConnection",
        items:  Array< {
          __typename: "DishOptionItem",
          id: string,
          title: string,
          description?: string | null,
          price: number,
          dishOptionID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      dishIDs?: Array< string | null > | null,
      restaurantID?: string | null,
      basketDishID?: string | null,
      orderDishID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDishOptionsQueryVariables = {
  filter?: ModelDishOptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDishOptionsQuery = {
  syncDishOptions?:  {
    __typename: "ModelDishOptionConnection",
    items:  Array< {
      __typename: "DishOption",
      id: string,
      title?: string | null,
      description?: string | null,
      multipleSelection?: boolean | null,
      obligatory?: boolean | null,
      items?:  {
        __typename: "ModelDishOptionItemConnection",
        items:  Array< {
          __typename: "DishOptionItem",
          id: string,
          title: string,
          description?: string | null,
          price: number,
          dishOptionID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      dishIDs?: Array< string | null > | null,
      restaurantID?: string | null,
      basketDishID?: string | null,
      orderDishID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDishQueryVariables = {
  id: string,
};

export type GetDishQuery = {
  getDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type ListDishesQueryVariables = {
  filter?: ModelDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDishesQuery = {
  listDishes?:  {
    __typename: "ModelDishConnection",
    items:  Array< {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDishesQueryVariables = {
  filter?: ModelDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDishesQuery = {
  syncDishes?:  {
    __typename: "ModelDishConnection",
    items:  Array< {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBusinessHourRestaurantQueryVariables = {
  id: string,
};

export type GetBusinessHourRestaurantQuery = {
  getBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBusinessHourRestaurantsQueryVariables = {
  filter?: ModelBusinessHourRestaurantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessHourRestaurantsQuery = {
  listBusinessHourRestaurants?:  {
    __typename: "ModelBusinessHourRestaurantConnection",
    items:  Array< {
      __typename: "BusinessHourRestaurant",
      id: string,
      restaurantID: string,
      periods?:  Array< {
        __typename: "BusinessHourPeriod",
        label?: string | null,
        start?: string | null,
        end?: string | null,
      } | null > | null,
      day?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBusinessHourRestaurantsQueryVariables = {
  filter?: ModelBusinessHourRestaurantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBusinessHourRestaurantsQuery = {
  syncBusinessHourRestaurants?:  {
    __typename: "ModelBusinessHourRestaurantConnection",
    items:  Array< {
      __typename: "BusinessHourRestaurant",
      id: string,
      restaurantID: string,
      periods?:  Array< {
        __typename: "BusinessHourPeriod",
        label?: string | null,
        start?: string | null,
        end?: string | null,
      } | null > | null,
      day?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBusinessHourByRestaurantIDQueryVariables = {
  restaurantID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessHourRestaurantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessHourByRestaurantIDQuery = {
  listBusinessHourByRestaurantID?:  {
    __typename: "ModelBusinessHourRestaurantConnection",
    items:  Array< {
      __typename: "BusinessHourRestaurant",
      id: string,
      restaurantID: string,
      periods?:  Array< {
        __typename: "BusinessHourPeriod",
        label?: string | null,
        start?: string | null,
        end?: string | null,
      } | null > | null,
      day?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBusinessHourTastyQueryVariables = {
  id: string,
};

export type GetBusinessHourTastyQuery = {
  getBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBusinessHourTastiesQueryVariables = {
  filter?: ModelBusinessHourTastyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessHourTastiesQuery = {
  listBusinessHourTasties?:  {
    __typename: "ModelBusinessHourTastyConnection",
    items:  Array< {
      __typename: "BusinessHourTasty",
      id: string,
      day?: number | null,
      appConfigID: string,
      periods?:  Array< {
        __typename: "BusinessHourPeriod",
        label?: string | null,
        start?: string | null,
        end?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBusinessHourTastiesQueryVariables = {
  filter?: ModelBusinessHourTastyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBusinessHourTastiesQuery = {
  syncBusinessHourTasties?:  {
    __typename: "ModelBusinessHourTastyConnection",
    items:  Array< {
      __typename: "BusinessHourTasty",
      id: string,
      day?: number | null,
      appConfigID: string,
      periods?:  Array< {
        __typename: "BusinessHourPeriod",
        label?: string | null,
        start?: string | null,
        end?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBusinessHourByAppConfigIDQueryVariables = {
  appConfigID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessHourTastyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessHourByAppConfigIDQuery = {
  listBusinessHourByAppConfigID?:  {
    __typename: "ModelBusinessHourTastyConnection",
    items:  Array< {
      __typename: "BusinessHourTasty",
      id: string,
      day?: number | null,
      appConfigID: string,
      periods?:  Array< {
        __typename: "BusinessHourPeriod",
        label?: string | null,
        start?: string | null,
        end?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAppConfigQueryVariables = {
  id: string,
};

export type GetAppConfigQuery = {
  getAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAppConfigsQueryVariables = {
  filter?: ModelAppConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppConfigsQuery = {
  listAppConfigs?:  {
    __typename: "ModelAppConfigConnection",
    items:  Array< {
      __typename: "AppConfig",
      id: string,
      customerFee?: number | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      minimumOrderValue?: number | null,
      openingHours?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourTastyConnection",
        items:  Array< {
          __typename: "BusinessHourTasty",
          id: string,
          day?: number | null,
          appConfigID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      maintenance?:  {
        __typename: "Maintenance",
        active?: boolean | null,
        title?: string | null,
        description?: string | null,
        type?: string | null,
      } | null,
      taxRate?: number | null,
      customData?: string | null,
      banner?:  {
        __typename: "Banner",
        visible?: boolean | null,
        imageURL?: string | null,
        linkURL?: string | null,
      } | null,
      orderStop?:  {
        __typename: "OrderStop",
        active?: boolean | null,
        title?: string | null,
        description?: string | null,
      } | null,
      voucherEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAppConfigsQueryVariables = {
  filter?: ModelAppConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAppConfigsQuery = {
  syncAppConfigs?:  {
    __typename: "ModelAppConfigConnection",
    items:  Array< {
      __typename: "AppConfig",
      id: string,
      customerFee?: number | null,
      deliveryFee?: number | null,
      restaurantFee?: number | null,
      minimumOrderValue?: number | null,
      openingHours?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourTastyConnection",
        items:  Array< {
          __typename: "BusinessHourTasty",
          id: string,
          day?: number | null,
          appConfigID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      maintenance?:  {
        __typename: "Maintenance",
        active?: boolean | null,
        title?: string | null,
        description?: string | null,
        type?: string | null,
      } | null,
      taxRate?: number | null,
      customData?: string | null,
      banner?:  {
        __typename: "Banner",
        visible?: boolean | null,
        imageURL?: string | null,
        linkURL?: string | null,
      } | null,
      orderStop?:  {
        __typename: "OrderStop",
        active?: boolean | null,
        title?: string | null,
        description?: string | null,
      } | null,
      voucherEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDriverQueryVariables = {
  id: string,
};

export type GetDriverQuery = {
  getDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDriversQueryVariables = {
  filter?: ModelDriverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDriversQuery = {
  listDrivers?:  {
    __typename: "ModelDriverConnection",
    items:  Array< {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDriversQueryVariables = {
  filter?: ModelDriverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDriversQuery = {
  syncDrivers?:  {
    __typename: "ModelDriverConnection",
    items:  Array< {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      name: string,
      address: string,
      addressData?:  {
        __typename: "AddressData",
        street?: string | null,
        city?: string | null,
        country?: string | null,
        district?: string | null,
        formattedAddress?: string | null,
        lat?: number | null,
        lng?: number | null,
      } | null,
      email?: string | null,
      phone?: string | null,
      lat: number,
      lng: number,
      Orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          userID: string,
          total: number,
          totalPaid?: number | null,
          voucherCode?: string | null,
          voucherAmountOff?: number | null,
          voucherType?: string | null,
          voucherPercentOff?: number | null,
          status: OrderStatus,
          read?: boolean | null,
          printed?: boolean | null,
          orderNumber?: string | null,
          note?: string | null,
          ready?: boolean | null,
          createdAt?: string | null,
          orderRestaurantId?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          orderDriverId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deliveryAddressID?: string | null,
      DeliveryAddresses?:  {
        __typename: "ModelDeliveryAddressConnection",
        items:  Array< {
          __typename: "DeliveryAddress",
          id: string,
          name?: string | null,
          userID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      newsletter?: boolean | null,
      company?: string | null,
      birthday?: string | null,
      stripeCustomerID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      name: string,
      address: string,
      addressData?:  {
        __typename: "AddressData",
        street?: string | null,
        city?: string | null,
        country?: string | null,
        district?: string | null,
        formattedAddress?: string | null,
        lat?: number | null,
        lng?: number | null,
      } | null,
      email?: string | null,
      phone?: string | null,
      lat: number,
      lng: number,
      Orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          userID: string,
          total: number,
          totalPaid?: number | null,
          voucherCode?: string | null,
          voucherAmountOff?: number | null,
          voucherType?: string | null,
          voucherPercentOff?: number | null,
          status: OrderStatus,
          read?: boolean | null,
          printed?: boolean | null,
          orderNumber?: string | null,
          note?: string | null,
          ready?: boolean | null,
          createdAt?: string | null,
          orderRestaurantId?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          orderDriverId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deliveryAddressID?: string | null,
      DeliveryAddresses?:  {
        __typename: "ModelDeliveryAddressConnection",
        items:  Array< {
          __typename: "DeliveryAddress",
          id: string,
          name?: string | null,
          userID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      newsletter?: boolean | null,
      company?: string | null,
      birthday?: string | null,
      stripeCustomerID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUsersBySubQueryVariables = {
  sub: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersBySubQuery = {
  listUsersBySub?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      sub: string,
      name: string,
      address: string,
      addressData?:  {
        __typename: "AddressData",
        street?: string | null,
        city?: string | null,
        country?: string | null,
        district?: string | null,
        formattedAddress?: string | null,
        lat?: number | null,
        lng?: number | null,
      } | null,
      email?: string | null,
      phone?: string | null,
      lat: number,
      lng: number,
      Orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          userID: string,
          total: number,
          totalPaid?: number | null,
          voucherCode?: string | null,
          voucherAmountOff?: number | null,
          voucherType?: string | null,
          voucherPercentOff?: number | null,
          status: OrderStatus,
          read?: boolean | null,
          printed?: boolean | null,
          orderNumber?: string | null,
          note?: string | null,
          ready?: boolean | null,
          createdAt?: string | null,
          orderRestaurantId?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          orderDriverId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deliveryAddressID?: string | null,
      DeliveryAddresses?:  {
        __typename: "ModelDeliveryAddressConnection",
        items:  Array< {
          __typename: "DeliveryAddress",
          id: string,
          name?: string | null,
          userID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      newsletter?: boolean | null,
      company?: string | null,
      birthday?: string | null,
      stripeCustomerID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBasketDishQueryVariables = {
  id: string,
};

export type GetBasketDishQuery = {
  getBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type ListBasketDishesQueryVariables = {
  filter?: ModelBasketDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBasketDishesQuery = {
  listBasketDishes?:  {
    __typename: "ModelBasketDishConnection",
    items:  Array< {
      __typename: "BasketDish",
      id: string,
      quantity: number,
      Dish?:  {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null,
      basketID: string,
      selectedDishOptions?:  {
        __typename: "ModelDishOptionConnection",
        items:  Array< {
          __typename: "DishOption",
          id: string,
          title?: string | null,
          description?: string | null,
          multipleSelection?: boolean | null,
          obligatory?: boolean | null,
          dishIDs?: Array< string | null > | null,
          restaurantID?: string | null,
          basketDishID?: string | null,
          orderDishID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      selectedDishOptionsJSON?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      basketDishDishId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBasketDishesQueryVariables = {
  filter?: ModelBasketDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBasketDishesQuery = {
  syncBasketDishes?:  {
    __typename: "ModelBasketDishConnection",
    items:  Array< {
      __typename: "BasketDish",
      id: string,
      quantity: number,
      Dish?:  {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null,
      basketID: string,
      selectedDishOptions?:  {
        __typename: "ModelDishOptionConnection",
        items:  Array< {
          __typename: "DishOption",
          id: string,
          title?: string | null,
          description?: string | null,
          multipleSelection?: boolean | null,
          obligatory?: boolean | null,
          dishIDs?: Array< string | null > | null,
          restaurantID?: string | null,
          basketDishID?: string | null,
          orderDishID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      selectedDishOptionsJSON?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      basketDishDishId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBasketDishesByBasketIDQueryVariables = {
  basketID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBasketDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBasketDishesByBasketIDQuery = {
  listBasketDishesByBasketID?:  {
    __typename: "ModelBasketDishConnection",
    items:  Array< {
      __typename: "BasketDish",
      id: string,
      quantity: number,
      Dish?:  {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null,
      basketID: string,
      selectedDishOptions?:  {
        __typename: "ModelDishOptionConnection",
        items:  Array< {
          __typename: "DishOption",
          id: string,
          title?: string | null,
          description?: string | null,
          multipleSelection?: boolean | null,
          obligatory?: boolean | null,
          dishIDs?: Array< string | null > | null,
          restaurantID?: string | null,
          basketDishID?: string | null,
          orderDishID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      selectedDishOptionsJSON?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      basketDishDishId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBasketQueryVariables = {
  id: string,
};

export type GetBasketQuery = {
  getBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBasketsQueryVariables = {
  filter?: ModelBasketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBasketsQuery = {
  listBaskets?:  {
    __typename: "ModelBasketConnection",
    items:  Array< {
      __typename: "Basket",
      id: string,
      BasketDishes?:  {
        __typename: "ModelBasketDishConnection",
        items:  Array< {
          __typename: "BasketDish",
          id: string,
          quantity: number,
          basketID: string,
          selectedDishOptionsJSON?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          basketDishDishId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID: string,
      restaurantID: string,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBasketsQueryVariables = {
  filter?: ModelBasketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBasketsQuery = {
  syncBaskets?:  {
    __typename: "ModelBasketConnection",
    items:  Array< {
      __typename: "Basket",
      id: string,
      BasketDishes?:  {
        __typename: "ModelBasketDishConnection",
        items:  Array< {
          __typename: "BasketDish",
          id: string,
          quantity: number,
          basketID: string,
          selectedDishOptionsJSON?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          basketDishDishId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID: string,
      restaurantID: string,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderDishQueryVariables = {
  id: string,
};

export type GetOrderDishQuery = {
  getOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type ListOrderDishesQueryVariables = {
  filter?: ModelOrderDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDishesQuery = {
  listOrderDishes?:  {
    __typename: "ModelOrderDishConnection",
    items:  Array< {
      __typename: "OrderDish",
      id: string,
      quantity: number,
      Dish?:  {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null,
      orderID: string,
      selectedDishOptions?:  {
        __typename: "ModelDishOptionConnection",
        items:  Array< {
          __typename: "DishOption",
          id: string,
          title?: string | null,
          description?: string | null,
          multipleSelection?: boolean | null,
          obligatory?: boolean | null,
          dishIDs?: Array< string | null > | null,
          restaurantID?: string | null,
          basketDishID?: string | null,
          orderDishID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      selectedDishOptionsJSON?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      orderDishDishId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderDishesQueryVariables = {
  filter?: ModelOrderDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderDishesQuery = {
  syncOrderDishes?:  {
    __typename: "ModelOrderDishConnection",
    items:  Array< {
      __typename: "OrderDish",
      id: string,
      quantity: number,
      Dish?:  {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null,
      orderID: string,
      selectedDishOptions?:  {
        __typename: "ModelDishOptionConnection",
        items:  Array< {
          __typename: "DishOption",
          id: string,
          title?: string | null,
          description?: string | null,
          multipleSelection?: boolean | null,
          obligatory?: boolean | null,
          dishIDs?: Array< string | null > | null,
          restaurantID?: string | null,
          basketDishID?: string | null,
          orderDishID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      selectedDishOptionsJSON?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      orderDishDishId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrderDishesByOrderIDQueryVariables = {
  orderID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDishesByOrderIDQuery = {
  listOrderDishesByOrderID?:  {
    __typename: "ModelOrderDishConnection",
    items:  Array< {
      __typename: "OrderDish",
      id: string,
      quantity: number,
      Dish?:  {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null,
      orderID: string,
      selectedDishOptions?:  {
        __typename: "ModelDishOptionConnection",
        items:  Array< {
          __typename: "DishOption",
          id: string,
          title?: string | null,
          description?: string | null,
          multipleSelection?: boolean | null,
          obligatory?: boolean | null,
          dishIDs?: Array< string | null > | null,
          restaurantID?: string | null,
          basketDishID?: string | null,
          orderDishID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      selectedDishOptionsJSON?: string | null,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      orderDishDishId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      userID: string,
      Restaurant?:  {
        __typename: "Restaurant",
        id: string,
        adminSub?: string | null,
        listRanking?: number | null,
        name: string,
        image?:  {
          __typename: "ImageData",
          logoKey?: string | null,
          teaserKey?: string | null,
        } | null,
        minDeliveryTime: number,
        maxDeliveryTime: number,
        rating?: number | null,
        address: string,
        lat: number,
        lng: number,
        Dishes?:  {
          __typename: "ModelDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Baskets?:  {
          __typename: "ModelBasketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        categories?:  {
          __typename: "ModelRestaurantCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        imageStorageKey?: string | null,
        status?: RestaurantStatus | null,
        invoices?:  {
          __typename: "ModelInvoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        phone?: string | null,
        email?: string | null,
        emailPrinter?: string | null,
        autoPickUp?: string | null,
        customData?: string | null,
        billingDetails?:  {
          __typename: "BillingDetails",
          email?: string | null,
          name?: string | null,
          phone?: string | null,
        } | null,
        printerGCMToken?: string | null,
        businessHours?:  {
          __typename: "ModelBusinessHourRestaurantConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        loggingEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      total: number,
      totalPaid?: number | null,
      voucherCode?: string | null,
      voucherAmountOff?: number | null,
      voucherType?: string | null,
      voucherPercentOff?: number | null,
      status: OrderStatus,
      OrderDishes?:  {
        __typename: "ModelOrderDishConnection",
        items:  Array< {
          __typename: "OrderDish",
          id: string,
          quantity: number,
          orderID: string,
          selectedDishOptionsJSON?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          orderDishDishId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Driver?:  {
        __typename: "Driver",
        id: string,
        name: string,
        sub: string,
        email?: string | null,
        password?: string | null,
        lat?: number | null,
        lng?: number | null,
        transportationMode: TransportationModes,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      read?: boolean | null,
      printed?: boolean | null,
      orderNumber?: string | null,
      note?: string | null,
      ready?: boolean | null,
      createdAt?: string | null,
      orderRestaurantId?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      orderDriverId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrdersQuery = {
  syncOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      userID: string,
      Restaurant?:  {
        __typename: "Restaurant",
        id: string,
        adminSub?: string | null,
        listRanking?: number | null,
        name: string,
        image?:  {
          __typename: "ImageData",
          logoKey?: string | null,
          teaserKey?: string | null,
        } | null,
        minDeliveryTime: number,
        maxDeliveryTime: number,
        rating?: number | null,
        address: string,
        lat: number,
        lng: number,
        Dishes?:  {
          __typename: "ModelDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Baskets?:  {
          __typename: "ModelBasketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        categories?:  {
          __typename: "ModelRestaurantCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        imageStorageKey?: string | null,
        status?: RestaurantStatus | null,
        invoices?:  {
          __typename: "ModelInvoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        phone?: string | null,
        email?: string | null,
        emailPrinter?: string | null,
        autoPickUp?: string | null,
        customData?: string | null,
        billingDetails?:  {
          __typename: "BillingDetails",
          email?: string | null,
          name?: string | null,
          phone?: string | null,
        } | null,
        printerGCMToken?: string | null,
        businessHours?:  {
          __typename: "ModelBusinessHourRestaurantConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        loggingEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      total: number,
      totalPaid?: number | null,
      voucherCode?: string | null,
      voucherAmountOff?: number | null,
      voucherType?: string | null,
      voucherPercentOff?: number | null,
      status: OrderStatus,
      OrderDishes?:  {
        __typename: "ModelOrderDishConnection",
        items:  Array< {
          __typename: "OrderDish",
          id: string,
          quantity: number,
          orderID: string,
          selectedDishOptionsJSON?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          orderDishDishId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Driver?:  {
        __typename: "Driver",
        id: string,
        name: string,
        sub: string,
        email?: string | null,
        password?: string | null,
        lat?: number | null,
        lng?: number | null,
        transportationMode: TransportationModes,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      read?: boolean | null,
      printed?: boolean | null,
      orderNumber?: string | null,
      note?: string | null,
      ready?: boolean | null,
      createdAt?: string | null,
      orderRestaurantId?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      orderDriverId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrdersByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersByUserIDQuery = {
  listOrdersByUserID?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      userID: string,
      Restaurant?:  {
        __typename: "Restaurant",
        id: string,
        adminSub?: string | null,
        listRanking?: number | null,
        name: string,
        image?:  {
          __typename: "ImageData",
          logoKey?: string | null,
          teaserKey?: string | null,
        } | null,
        minDeliveryTime: number,
        maxDeliveryTime: number,
        rating?: number | null,
        address: string,
        lat: number,
        lng: number,
        Dishes?:  {
          __typename: "ModelDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Baskets?:  {
          __typename: "ModelBasketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        categories?:  {
          __typename: "ModelRestaurantCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        imageStorageKey?: string | null,
        status?: RestaurantStatus | null,
        invoices?:  {
          __typename: "ModelInvoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        phone?: string | null,
        email?: string | null,
        emailPrinter?: string | null,
        autoPickUp?: string | null,
        customData?: string | null,
        billingDetails?:  {
          __typename: "BillingDetails",
          email?: string | null,
          name?: string | null,
          phone?: string | null,
        } | null,
        printerGCMToken?: string | null,
        businessHours?:  {
          __typename: "ModelBusinessHourRestaurantConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        loggingEnabled?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      total: number,
      totalPaid?: number | null,
      voucherCode?: string | null,
      voucherAmountOff?: number | null,
      voucherType?: string | null,
      voucherPercentOff?: number | null,
      status: OrderStatus,
      OrderDishes?:  {
        __typename: "ModelOrderDishConnection",
        items:  Array< {
          __typename: "OrderDish",
          id: string,
          quantity: number,
          orderID: string,
          selectedDishOptionsJSON?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          orderDishDishId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Driver?:  {
        __typename: "Driver",
        id: string,
        name: string,
        sub: string,
        email?: string | null,
        password?: string | null,
        lat?: number | null,
        lng?: number | null,
        transportationMode: TransportationModes,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      read?: boolean | null,
      printed?: boolean | null,
      orderNumber?: string | null,
      note?: string | null,
      ready?: boolean | null,
      createdAt?: string | null,
      orderRestaurantId?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      orderDriverId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDeliveryAddressQueryVariables = {
  id: string,
};

export type GetDeliveryAddressQuery = {
  getDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDeliveryAddressesQueryVariables = {
  filter?: ModelDeliveryAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeliveryAddressesQuery = {
  listDeliveryAddresses?:  {
    __typename: "ModelDeliveryAddressConnection",
    items:  Array< {
      __typename: "DeliveryAddress",
      id: string,
      name?: string | null,
      addressData?:  {
        __typename: "AddressData",
        street?: string | null,
        city?: string | null,
        country?: string | null,
        district?: string | null,
        formattedAddress?: string | null,
        lat?: number | null,
        lng?: number | null,
      } | null,
      userID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDeliveryAddressesQueryVariables = {
  filter?: ModelDeliveryAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDeliveryAddressesQuery = {
  syncDeliveryAddresses?:  {
    __typename: "ModelDeliveryAddressConnection",
    items:  Array< {
      __typename: "DeliveryAddress",
      id: string,
      name?: string | null,
      addressData?:  {
        __typename: "AddressData",
        street?: string | null,
        city?: string | null,
        country?: string | null,
        district?: string | null,
        formattedAddress?: string | null,
        lat?: number | null,
        lng?: number | null,
      } | null,
      userID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRestaurantCategoryQueryVariables = {
  id: string,
};

export type GetRestaurantCategoryQuery = {
  getRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRestaurantCategoriesQueryVariables = {
  filter?: ModelRestaurantCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRestaurantCategoriesQuery = {
  listRestaurantCategories?:  {
    __typename: "ModelRestaurantCategoryConnection",
    items:  Array< {
      __typename: "RestaurantCategory",
      id: string,
      name?: string | null,
      restaurantID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRestaurantCategoriesQueryVariables = {
  filter?: ModelRestaurantCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRestaurantCategoriesQuery = {
  syncRestaurantCategories?:  {
    __typename: "ModelRestaurantCategoryConnection",
    items:  Array< {
      __typename: "RestaurantCategory",
      id: string,
      name?: string | null,
      restaurantID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRestaurantQueryVariables = {
  id: string,
};

export type GetRestaurantQuery = {
  getRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRestaurantsQueryVariables = {
  filter?: ModelRestaurantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRestaurantsQuery = {
  listRestaurants?:  {
    __typename: "ModelRestaurantConnection",
    items:  Array< {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRestaurantsQueryVariables = {
  filter?: ModelRestaurantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRestaurantsQuery = {
  syncRestaurants?:  {
    __typename: "ModelRestaurantConnection",
    items:  Array< {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnOrderByRestaurantIdSubscriptionVariables = {
  orderRestaurantId: string,
};

export type OnOrderByRestaurantIdSubscription = {
  onOrderByRestaurantId?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type OnCreateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount?: number | null,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    customerID: string,
    customerEmail?: string | null,
    restaurantID: string,
    paymentIntent?: string | null,
    paymentIntentID?: string | null,
    details?:  Array< {
      __typename: "InvoiceDetail",
      name?: string | null,
      description?: string | null,
      price?: number | null,
      options?:  Array< {
        __typename: "InvoiceOptions",
        title?: string | null,
        items?:  Array< {
          __typename: "InvoiceOptionItem",
          title?: string | null,
          price?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    pdfInvoice?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDishCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDishCategoryFilterInput | null,
};

export type OnCreateDishCategorySubscription = {
  onCreateDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDishCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDishCategoryFilterInput | null,
};

export type OnUpdateDishCategorySubscription = {
  onUpdateDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDishCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDishCategoryFilterInput | null,
};

export type OnDeleteDishCategorySubscription = {
  onDeleteDishCategory?:  {
    __typename: "DishCategory",
    id: string,
    name: string,
    description?: string | null,
    imageKey?: string | null,
    restaurantID?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDishOptionItemSubscriptionVariables = {
  filter?: ModelSubscriptionDishOptionItemFilterInput | null,
};

export type OnCreateDishOptionItemSubscription = {
  onCreateDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDishOptionItemSubscriptionVariables = {
  filter?: ModelSubscriptionDishOptionItemFilterInput | null,
};

export type OnUpdateDishOptionItemSubscription = {
  onUpdateDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDishOptionItemSubscriptionVariables = {
  filter?: ModelSubscriptionDishOptionItemFilterInput | null,
};

export type OnDeleteDishOptionItemSubscription = {
  onDeleteDishOptionItem?:  {
    __typename: "DishOptionItem",
    id: string,
    title: string,
    description?: string | null,
    price: number,
    dishOptionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDishOptionSubscriptionVariables = {
  filter?: ModelSubscriptionDishOptionFilterInput | null,
};

export type OnCreateDishOptionSubscription = {
  onCreateDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDishOptionSubscriptionVariables = {
  filter?: ModelSubscriptionDishOptionFilterInput | null,
};

export type OnUpdateDishOptionSubscription = {
  onUpdateDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDishOptionSubscriptionVariables = {
  filter?: ModelSubscriptionDishOptionFilterInput | null,
};

export type OnDeleteDishOptionSubscription = {
  onDeleteDishOption?:  {
    __typename: "DishOption",
    id: string,
    title?: string | null,
    description?: string | null,
    multipleSelection?: boolean | null,
    obligatory?: boolean | null,
    items?:  {
      __typename: "ModelDishOptionItemConnection",
      items:  Array< {
        __typename: "DishOptionItem",
        id: string,
        title: string,
        description?: string | null,
        price: number,
        dishOptionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    dishIDs?: Array< string | null > | null,
    restaurantID?: string | null,
    basketDishID?: string | null,
    orderDishID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDishSubscriptionVariables = {
  filter?: ModelSubscriptionDishFilterInput | null,
};

export type OnCreateDishSubscription = {
  onCreateDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type OnUpdateDishSubscriptionVariables = {
  filter?: ModelSubscriptionDishFilterInput | null,
};

export type OnUpdateDishSubscription = {
  onUpdateDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type OnDeleteDishSubscriptionVariables = {
  filter?: ModelSubscriptionDishFilterInput | null,
};

export type OnDeleteDishSubscription = {
  onDeleteDish?:  {
    __typename: "Dish",
    id: string,
    name: string,
    image?: string | null,
    description: string,
    shortDescription?: string | null,
    price: number,
    restaurantID: string,
    soldOut?: boolean | null,
    category?:  {
      __typename: "DishCategory",
      id: string,
      name: string,
      description?: string | null,
      imageKey?: string | null,
      restaurantID?: string | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    allergens?:  Array< {
      __typename: "Allergens",
      id?: string | null,
      name?: string | null,
    } | null > | null,
    dishType?: DishType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    dishCategoryId?: string | null,
  } | null,
};

export type OnCreateBusinessHourRestaurantSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessHourRestaurantFilterInput | null,
};

export type OnCreateBusinessHourRestaurantSubscription = {
  onCreateBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBusinessHourRestaurantSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessHourRestaurantFilterInput | null,
};

export type OnUpdateBusinessHourRestaurantSubscription = {
  onUpdateBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBusinessHourRestaurantSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessHourRestaurantFilterInput | null,
};

export type OnDeleteBusinessHourRestaurantSubscription = {
  onDeleteBusinessHourRestaurant?:  {
    __typename: "BusinessHourRestaurant",
    id: string,
    restaurantID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    day?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBusinessHourTastySubscriptionVariables = {
  filter?: ModelSubscriptionBusinessHourTastyFilterInput | null,
};

export type OnCreateBusinessHourTastySubscription = {
  onCreateBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBusinessHourTastySubscriptionVariables = {
  filter?: ModelSubscriptionBusinessHourTastyFilterInput | null,
};

export type OnUpdateBusinessHourTastySubscription = {
  onUpdateBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBusinessHourTastySubscriptionVariables = {
  filter?: ModelSubscriptionBusinessHourTastyFilterInput | null,
};

export type OnDeleteBusinessHourTastySubscription = {
  onDeleteBusinessHourTasty?:  {
    __typename: "BusinessHourTasty",
    id: string,
    day?: number | null,
    appConfigID: string,
    periods?:  Array< {
      __typename: "BusinessHourPeriod",
      label?: string | null,
      start?: string | null,
      end?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAppConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAppConfigFilterInput | null,
};

export type OnCreateAppConfigSubscription = {
  onCreateAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAppConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAppConfigFilterInput | null,
};

export type OnUpdateAppConfigSubscription = {
  onUpdateAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAppConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAppConfigFilterInput | null,
};

export type OnDeleteAppConfigSubscription = {
  onDeleteAppConfig?:  {
    __typename: "AppConfig",
    id: string,
    customerFee?: number | null,
    deliveryFee?: number | null,
    restaurantFee?: number | null,
    minimumOrderValue?: number | null,
    openingHours?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourTastyConnection",
      items:  Array< {
        __typename: "BusinessHourTasty",
        id: string,
        day?: number | null,
        appConfigID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    maintenance?:  {
      __typename: "Maintenance",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
      type?: string | null,
    } | null,
    taxRate?: number | null,
    customData?: string | null,
    banner?:  {
      __typename: "Banner",
      visible?: boolean | null,
      imageURL?: string | null,
      linkURL?: string | null,
    } | null,
    orderStop?:  {
      __typename: "OrderStop",
      active?: boolean | null,
      title?: string | null,
      description?: string | null,
    } | null,
    voucherEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDriverSubscriptionVariables = {
  filter?: ModelSubscriptionDriverFilterInput | null,
};

export type OnCreateDriverSubscription = {
  onCreateDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDriverSubscriptionVariables = {
  filter?: ModelSubscriptionDriverFilterInput | null,
};

export type OnUpdateDriverSubscription = {
  onUpdateDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDriverSubscriptionVariables = {
  filter?: ModelSubscriptionDriverFilterInput | null,
};

export type OnDeleteDriverSubscription = {
  onDeleteDriver?:  {
    __typename: "Driver",
    id: string,
    name: string,
    sub: string,
    email?: string | null,
    password?: string | null,
    lat?: number | null,
    lng?: number | null,
    transportationMode: TransportationModes,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    sub: string,
    name: string,
    address: string,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    email?: string | null,
    phone?: string | null,
    lat: number,
    lng: number,
    Orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        userID: string,
        Restaurant?:  {
          __typename: "Restaurant",
          id: string,
          adminSub?: string | null,
          listRanking?: number | null,
          name: string,
          minDeliveryTime: number,
          maxDeliveryTime: number,
          rating?: number | null,
          address: string,
          lat: number,
          lng: number,
          imageStorageKey?: string | null,
          status?: RestaurantStatus | null,
          phone?: string | null,
          email?: string | null,
          emailPrinter?: string | null,
          autoPickUp?: string | null,
          customData?: string | null,
          printerGCMToken?: string | null,
          loggingEnabled?: boolean | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        total: number,
        totalPaid?: number | null,
        voucherCode?: string | null,
        voucherAmountOff?: number | null,
        voucherType?: string | null,
        voucherPercentOff?: number | null,
        status: OrderStatus,
        OrderDishes?:  {
          __typename: "ModelOrderDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Driver?:  {
          __typename: "Driver",
          id: string,
          name: string,
          sub: string,
          email?: string | null,
          password?: string | null,
          lat?: number | null,
          lng?: number | null,
          transportationMode: TransportationModes,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        read?: boolean | null,
        printed?: boolean | null,
        orderNumber?: string | null,
        note?: string | null,
        ready?: boolean | null,
        createdAt?: string | null,
        orderRestaurantId?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDriverId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deliveryAddressID?: string | null,
    DeliveryAddresses?:  {
      __typename: "ModelDeliveryAddressConnection",
      items:  Array< {
        __typename: "DeliveryAddress",
        id: string,
        name?: string | null,
        addressData?:  {
          __typename: "AddressData",
          street?: string | null,
          city?: string | null,
          country?: string | null,
          district?: string | null,
          formattedAddress?: string | null,
          lat?: number | null,
          lng?: number | null,
        } | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    newsletter?: boolean | null,
    company?: string | null,
    birthday?: string | null,
    stripeCustomerID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBasketDishSubscriptionVariables = {
  filter?: ModelSubscriptionBasketDishFilterInput | null,
};

export type OnCreateBasketDishSubscription = {
  onCreateBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type OnUpdateBasketDishSubscriptionVariables = {
  filter?: ModelSubscriptionBasketDishFilterInput | null,
};

export type OnUpdateBasketDishSubscription = {
  onUpdateBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type OnDeleteBasketDishSubscriptionVariables = {
  filter?: ModelSubscriptionBasketDishFilterInput | null,
};

export type OnDeleteBasketDishSubscription = {
  onDeleteBasketDish?:  {
    __typename: "BasketDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    basketID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    basketDishDishId?: string | null,
  } | null,
};

export type OnCreateBasketSubscriptionVariables = {
  filter?: ModelSubscriptionBasketFilterInput | null,
};

export type OnCreateBasketSubscription = {
  onCreateBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBasketSubscriptionVariables = {
  filter?: ModelSubscriptionBasketFilterInput | null,
};

export type OnUpdateBasketSubscription = {
  onUpdateBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBasketSubscriptionVariables = {
  filter?: ModelSubscriptionBasketFilterInput | null,
};

export type OnDeleteBasketSubscription = {
  onDeleteBasket?:  {
    __typename: "Basket",
    id: string,
    BasketDishes?:  {
      __typename: "ModelBasketDishConnection",
      items:  Array< {
        __typename: "BasketDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        basketID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        basketDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    restaurantID: string,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrderDishSubscriptionVariables = {
  filter?: ModelSubscriptionOrderDishFilterInput | null,
};

export type OnCreateOrderDishSubscription = {
  onCreateOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type OnUpdateOrderDishSubscriptionVariables = {
  filter?: ModelSubscriptionOrderDishFilterInput | null,
};

export type OnUpdateOrderDishSubscription = {
  onUpdateOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type OnDeleteOrderDishSubscriptionVariables = {
  filter?: ModelSubscriptionOrderDishFilterInput | null,
};

export type OnDeleteOrderDishSubscription = {
  onDeleteOrderDish?:  {
    __typename: "OrderDish",
    id: string,
    quantity: number,
    Dish?:  {
      __typename: "Dish",
      id: string,
      name: string,
      image?: string | null,
      description: string,
      shortDescription?: string | null,
      price: number,
      restaurantID: string,
      soldOut?: boolean | null,
      category?:  {
        __typename: "DishCategory",
        id: string,
        name: string,
        description?: string | null,
        imageKey?: string | null,
        restaurantID?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      allergens?:  Array< {
        __typename: "Allergens",
        id?: string | null,
        name?: string | null,
      } | null > | null,
      dishType?: DishType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      dishCategoryId?: string | null,
    } | null,
    orderID: string,
    selectedDishOptions?:  {
      __typename: "ModelDishOptionConnection",
      items:  Array< {
        __typename: "DishOption",
        id: string,
        title?: string | null,
        description?: string | null,
        multipleSelection?: boolean | null,
        obligatory?: boolean | null,
        items?:  {
          __typename: "ModelDishOptionItemConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        dishIDs?: Array< string | null > | null,
        restaurantID?: string | null,
        basketDishID?: string | null,
        orderDishID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    selectedDishOptionsJSON?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDishDishId?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    Restaurant?:  {
      __typename: "Restaurant",
      id: string,
      adminSub?: string | null,
      listRanking?: number | null,
      name: string,
      image?:  {
        __typename: "ImageData",
        logoKey?: string | null,
        teaserKey?: string | null,
      } | null,
      minDeliveryTime: number,
      maxDeliveryTime: number,
      rating?: number | null,
      address: string,
      lat: number,
      lng: number,
      Dishes?:  {
        __typename: "ModelDishConnection",
        items:  Array< {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Baskets?:  {
        __typename: "ModelBasketConnection",
        items:  Array< {
          __typename: "Basket",
          id: string,
          userID: string,
          restaurantID: string,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      categories?:  {
        __typename: "ModelRestaurantCategoryConnection",
        items:  Array< {
          __typename: "RestaurantCategory",
          id: string,
          name?: string | null,
          restaurantID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      imageStorageKey?: string | null,
      status?: RestaurantStatus | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        items:  Array< {
          __typename: "Invoice",
          id: string,
          amount?: number | null,
          customerFee?: number | null,
          deliveryFee?: number | null,
          restaurantFee?: number | null,
          customerID: string,
          customerEmail?: string | null,
          restaurantID: string,
          paymentIntent?: string | null,
          paymentIntentID?: string | null,
          pdfInvoice?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phone?: string | null,
      email?: string | null,
      emailPrinter?: string | null,
      autoPickUp?: string | null,
      customData?: string | null,
      billingDetails?:  {
        __typename: "BillingDetails",
        address?:  {
          __typename: "Address",
          city?: string | null,
          country?: string | null,
          line1?: string | null,
          line2?: string | null,
          postal_code?: string | null,
          state?: string | null,
        } | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      } | null,
      printerGCMToken?: string | null,
      businessHours?:  {
        __typename: "ModelBusinessHourRestaurantConnection",
        items:  Array< {
          __typename: "BusinessHourRestaurant",
          id: string,
          restaurantID: string,
          day?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      loggingEnabled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    total: number,
    totalPaid?: number | null,
    voucherCode?: string | null,
    voucherAmountOff?: number | null,
    voucherType?: string | null,
    voucherPercentOff?: number | null,
    status: OrderStatus,
    OrderDishes?:  {
      __typename: "ModelOrderDishConnection",
      items:  Array< {
        __typename: "OrderDish",
        id: string,
        quantity: number,
        Dish?:  {
          __typename: "Dish",
          id: string,
          name: string,
          image?: string | null,
          description: string,
          shortDescription?: string | null,
          price: number,
          restaurantID: string,
          soldOut?: boolean | null,
          dishType?: DishType | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          dishCategoryId?: string | null,
        } | null,
        orderID: string,
        selectedDishOptions?:  {
          __typename: "ModelDishOptionConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        selectedDishOptionsJSON?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        orderDishDishId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Driver?:  {
      __typename: "Driver",
      id: string,
      name: string,
      sub: string,
      email?: string | null,
      password?: string | null,
      lat?: number | null,
      lng?: number | null,
      transportationMode: TransportationModes,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    read?: boolean | null,
    printed?: boolean | null,
    orderNumber?: string | null,
    note?: string | null,
    ready?: boolean | null,
    createdAt?: string | null,
    orderRestaurantId?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    orderDriverId?: string | null,
  } | null,
};

export type OnCreateDeliveryAddressSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryAddressFilterInput | null,
};

export type OnCreateDeliveryAddressSubscription = {
  onCreateDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDeliveryAddressSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryAddressFilterInput | null,
};

export type OnUpdateDeliveryAddressSubscription = {
  onUpdateDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDeliveryAddressSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryAddressFilterInput | null,
};

export type OnDeleteDeliveryAddressSubscription = {
  onDeleteDeliveryAddress?:  {
    __typename: "DeliveryAddress",
    id: string,
    name?: string | null,
    addressData?:  {
      __typename: "AddressData",
      street?: string | null,
      city?: string | null,
      country?: string | null,
      district?: string | null,
      formattedAddress?: string | null,
      lat?: number | null,
      lng?: number | null,
    } | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRestaurantCategorySubscriptionVariables = {
  filter?: ModelSubscriptionRestaurantCategoryFilterInput | null,
};

export type OnCreateRestaurantCategorySubscription = {
  onCreateRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRestaurantCategorySubscriptionVariables = {
  filter?: ModelSubscriptionRestaurantCategoryFilterInput | null,
};

export type OnUpdateRestaurantCategorySubscription = {
  onUpdateRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRestaurantCategorySubscriptionVariables = {
  filter?: ModelSubscriptionRestaurantCategoryFilterInput | null,
};

export type OnDeleteRestaurantCategorySubscription = {
  onDeleteRestaurantCategory?:  {
    __typename: "RestaurantCategory",
    id: string,
    name?: string | null,
    restaurantID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRestaurantSubscriptionVariables = {
  filter?: ModelSubscriptionRestaurantFilterInput | null,
};

export type OnCreateRestaurantSubscription = {
  onCreateRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRestaurantSubscriptionVariables = {
  filter?: ModelSubscriptionRestaurantFilterInput | null,
};

export type OnUpdateRestaurantSubscription = {
  onUpdateRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRestaurantSubscriptionVariables = {
  filter?: ModelSubscriptionRestaurantFilterInput | null,
};

export type OnDeleteRestaurantSubscription = {
  onDeleteRestaurant?:  {
    __typename: "Restaurant",
    id: string,
    adminSub?: string | null,
    listRanking?: number | null,
    name: string,
    image?:  {
      __typename: "ImageData",
      logoKey?: string | null,
      teaserKey?: string | null,
    } | null,
    minDeliveryTime: number,
    maxDeliveryTime: number,
    rating?: number | null,
    address: string,
    lat: number,
    lng: number,
    Dishes?:  {
      __typename: "ModelDishConnection",
      items:  Array< {
        __typename: "Dish",
        id: string,
        name: string,
        image?: string | null,
        description: string,
        shortDescription?: string | null,
        price: number,
        restaurantID: string,
        soldOut?: boolean | null,
        category?:  {
          __typename: "DishCategory",
          id: string,
          name: string,
          description?: string | null,
          imageKey?: string | null,
          restaurantID?: string | null,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        allergens?:  Array< {
          __typename: "Allergens",
          id?: string | null,
          name?: string | null,
        } | null > | null,
        dishType?: DishType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        dishCategoryId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Baskets?:  {
      __typename: "ModelBasketConnection",
      items:  Array< {
        __typename: "Basket",
        id: string,
        BasketDishes?:  {
          __typename: "ModelBasketDishConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        userID: string,
        restaurantID: string,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    categories?:  {
      __typename: "ModelRestaurantCategoryConnection",
      items:  Array< {
        __typename: "RestaurantCategory",
        id: string,
        name?: string | null,
        restaurantID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    imageStorageKey?: string | null,
    status?: RestaurantStatus | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        amount?: number | null,
        customerFee?: number | null,
        deliveryFee?: number | null,
        restaurantFee?: number | null,
        customerID: string,
        customerEmail?: string | null,
        restaurantID: string,
        paymentIntent?: string | null,
        paymentIntentID?: string | null,
        details?:  Array< {
          __typename: "InvoiceDetail",
          name?: string | null,
          description?: string | null,
          price?: number | null,
        } | null > | null,
        pdfInvoice?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phone?: string | null,
    email?: string | null,
    emailPrinter?: string | null,
    autoPickUp?: string | null,
    customData?: string | null,
    billingDetails?:  {
      __typename: "BillingDetails",
      address?:  {
        __typename: "Address",
        city?: string | null,
        country?: string | null,
        line1?: string | null,
        line2?: string | null,
        postal_code?: string | null,
        state?: string | null,
      } | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    } | null,
    printerGCMToken?: string | null,
    businessHours?:  {
      __typename: "ModelBusinessHourRestaurantConnection",
      items:  Array< {
        __typename: "BusinessHourRestaurant",
        id: string,
        restaurantID: string,
        periods?:  Array< {
          __typename: "BusinessHourPeriod",
          label?: string | null,
          start?: string | null,
          end?: string | null,
        } | null > | null,
        day?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    loggingEnabled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
